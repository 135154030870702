import React from 'react'
import { IconProps } from '.'

export function LoginIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 16 14'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M6.725 3.775C6.4325 4.0675 6.4325 4.5325 6.725 4.825L8.15 6.25H1.25C0.8375 6.25 0.5 6.5875 0.5 7C0.5 7.4125 0.8375 7.75 1.25 7.75H8.15L6.725 9.175C6.4325 9.4675 6.4325 9.9325 6.725 10.225C7.0175 10.5175 7.4825 10.5175 7.775 10.225L10.4675 7.5325C10.76 7.24 10.76 6.7675 10.4675 6.475L7.775 3.775C7.4825 3.4825 7.0175 3.4825 6.725 3.775ZM14 12.25H8.75C8.3375 12.25 8 12.5875 8 13C8 13.4125 8.3375 13.75 8.75 13.75H14C14.825 13.75 15.5 13.075 15.5 12.25V1.75C15.5 0.925 14.825 0.25 14 0.25H8.75C8.3375 0.25 8 0.5875 8 1C8 1.4125 8.3375 1.75 8.75 1.75H14V12.25Z'
        fill='currentColor'
      />
    </svg>
  )
}
