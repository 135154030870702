import * as React from 'react'
import styles from './RecentlyViewed.module.scss'

export function RecentlyViewed({ url }: { url: string }) {
  return (
    <a href={url} className={`${styles.recent}`}>
      Recently Viewed
    </a>
  )
}
