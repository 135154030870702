import * as React from 'react'
import { IconProps } from '.'

export function YouTubeIcon({
  height = 25,
  width = 25,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 152 152'
      focusable='false'
      aria-hidden
      role='img'
      {...props}
    >
      <path d='m65.46 88.26 21.08-12.23-21.08-12.29z' />
      <path d='M76 0a76 76 0 1 0 76 76A76 76 0 0 0 76 0zm40 89.45a15.13 15.13 0 0 1-15.13 15.14H51.13A15.13 15.13 0 0 1 36 89.45v-26.9a15.13 15.13 0 0 1 15.13-15.14h49.74A15.13 15.13 0 0 1 116 62.55z' />
    </svg>
  )
}
