import React from 'react'
import { IconProps } from '.'
export function ChevronRightIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 24 24'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'
      ></path>
    </svg>
  )
}
