import * as React from 'react'
import { Dropdown } from '../../Dropdown/Dropdown'
import { User } from '../Header'
import { UserNavigation } from '../UserNavigation/UserNavigation'
import { Announcement } from './../../../index'
import { ChevronDownIcon } from './../../../theme/icons/ChevronDownIcon'
import styles from './Announcements.module.scss'
export interface AnnouncementProps {
  announcements: Array<Announcement>
  user: User
  cartCount: number
  cartUrl: string
}

export function Annoucements({
  announcements = [],
  cartCount,
  user,
  cartUrl,
}: AnnouncementProps) {
  const firstAnnouncement = announcements[0]

  return (
    <div data-testid='banner' className={styles.wrapper}>
      <div className={styles.inner}>
        <div>
          {firstAnnouncement && (
            <Dropdown
              buttonLabel={
                <>
                  {firstAnnouncement.title} <ChevronDownIcon />
                </>
              }
              buttonStyle={styles.title}
              dropdownStyle={styles.announcements}
              id='announcements-dropdown'
              ariaLabel='Announcements'
            >
              {announcements.map((announcement) => (
                <a
                  key={announcement.id}
                  href={announcement.url}
                  className={styles.announcement}
                >
                  {announcement.message}
                </a>
              ))}
            </Dropdown>
          )}
        </div>
        <div className={styles.userNav}>
          <UserNavigation cartCount={cartCount} user={user} cartUrl={cartUrl} />
        </div>
      </div>
    </div>
  )
}
