import { MenuLink } from '../Menu'

export const room: MenuLink[] = [
  { key: 'All Rooms', url: '/en/inspiration/rooms' },
  { key: 'Bathroom', url: '/en/inspiration/rooms/bathroom/bathroom-wallpaper' },
  {
    key: 'Bedroom',
    url: '/en/inspiration/rooms/bedroom/bedroom-wallpaper',
  },
  {
    key: 'Dining Room',
    url: '/en/inspiration/rooms/dining-room/dining-room-wallpaper',
  },
  {
    key: 'Entryway',
    url: '/en/inspiration/rooms/entryway/entryway-wallpaper',
  },
  {
    key: 'Home Office',
    url: '/en/inspiration/rooms/home-office/home-office-wallpaper',
  },
  {
    key: 'Kids Room',
    url: '/en/inspiration/rooms/kids-room/kids-room-wallpaper',
  },
  {
    key: 'Kitchen',
    url: '/en/inspiration/rooms/kitchen/kitchen-wallpaper',
  },
  {
    key: 'Laundry Room',
    url: '/en/inspiration/rooms/laundry/laundry-wallpaper',
  },
  {
    key: 'Nursery',
    url: '/en/inspiration/rooms/nursery/nursery-wallpaper',
  },
  {
    key: 'Renter-Friendly',
    url: '/en/inspiration/spaces/renter-friendly/renter-friendly-wallpaper',
  },
]
