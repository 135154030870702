import * as React from 'react'
import { useEffect, useRef } from 'react'
import styles from './Drawer.module.scss'

interface DrawerProps {
  isOpen: boolean
  toggleDrawer: () => void
  children: React.ReactNode
  onClose?: () => void
  left?: boolean
}

export function Drawer({
  isOpen,
  toggleDrawer,
  children,
  onClose,
  left,
}: DrawerProps) {
  const drawerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen && drawerRef.current) {
      setTimeout(() => {
        // Selecting the close button by its ID
        const closeButton = drawerRef?.current?.querySelector(
          '#drawerCloseButton'
        ) as HTMLElement
        if (closeButton) {
          closeButton.focus()
        }
      }, 300)
    } else {
      onClose?.()
    }
  }, [isOpen])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node)
    ) {
      toggleDrawer()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <>
      <div
        className={`${styles.overlay} ${isOpen ? styles.visible : ''} `}
        onClick={toggleDrawer}
      ></div>
      <div
        ref={drawerRef}
        className={`${styles.drawer} ${isOpen ? styles.open : ''} ${
          left ? styles.left : ''
        }`}
      >
        {isOpen && children}
      </div>
    </>
  )
}
