import * as React from 'react'
import { HeartFilledIcon } from '../../../theme/icons'
import { User } from '../Header'
import { ShoppingCartIcon } from './../../../theme/icons/ShoppingCartIcon'
import { ProfileMenu } from './ProfileMenu/ProfileMenu'
import styles from './UserNavigation.module.scss'

export interface UserNavigationProps {
  cartCount: number
  user: User
  cartUrl?: string
}

export function UserNavigation({
  cartCount,
  user,
  cartUrl,
}: UserNavigationProps) {
  if (user.isLoading) return null
  return (
    <div className={styles.wrapper}>
      {user.screen_name && (
        <>
          <a
            className={styles.userNavigation}
            href={`/favorites/${user.screen_name}`}
            title='Click to view favorites'
          >
            <HeartFilledIcon color={'#1e2223'} />
          </a>
          <ProfileMenu user={user} />
        </>
      )}
      {!user.screen_name && (
        <div className={styles.signedOut}>
          <a className={styles.userNavigation} href='/login'>
            Log In
          </a>
          <a className={styles.userNavigation} href='/register/new'>
            Join
          </a>
        </div>
      )}
      <a
        className={styles.cartWrapper}
        href={
          cartUrl
            ? cartUrl
            : user.stayLegacyCart
            ? 'https://spoonflower.com/en/cart'
            : 'https://cart.spoonflower.com'
        }
        title='View cart'
      >
        <ShoppingCartIcon />
        {cartCount > 0 && (
          <span className={styles.cartCount} aria-live='polite'>
            {cartCount}
          </span>
        )}
      </a>
    </div>
  )
}
