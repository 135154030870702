import React from 'react'
import { IconProps } from '.'

export function ArrowLeftIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 28 13'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M1.96099 5.88463L27.3862 5.88463C27.5683 5.88463 27.7161 5.94324 27.8297 6.06046C27.9432 6.17768 28 6.33028 28 6.51826C28 6.70624 27.9432 6.85886 27.8297 6.97611C27.7161 7.09333 27.5683 7.15194 27.3862 7.15194L1.92109 7.15194L6.08854 11.4544C6.19903 11.5685 6.25427 11.7058 6.25427 11.8663C6.25427 12.0269 6.19597 12.1673 6.07937 12.2877C5.94229 12.4292 5.796 12.5 5.64049 12.5C5.485 12.5 5.33872 12.4292 5.20164 12.2877L0.359055 7.31986C0.246525 7.20368 0.163662 7.07695 0.11047 6.93966C0.0572758 6.80236 0.0204544 6.6619 1.90735e-06 6.51826C1.90735e-06 6.35351 0.0317059 6.20778 0.0951118 6.08106C0.158545 5.95431 0.246525 5.83286 0.359055 5.71671L5.20164 0.685462C5.34487 0.558714 5.48757 0.496932 5.62976 0.500117C5.77194 0.503275 5.91157 0.575615 6.04865 0.717135C6.18573 0.858657 6.25427 1.01126 6.25427 1.17494C6.25427 1.33864 6.1888 1.48809 6.05787 1.62327L1.96099 5.88463Z'
      ></path>
    </svg>
  )
}
