import Translate from 'i18n-react';
import get from 'lodash/get';
import React from 'react';

import {
  ORDER_ITEM_TYPE_FABRIC_ITEM, ORDER_ITEM_TYPE_WALLPAPER_ITEM, ORDER_ITEM_TYPE_HOME_GOOD_ITEM, ORDER_ITEM_TYPE_UNPRINTED_ITEM, ORDER_ITEM_TYPE_KIT_ITEM,
  ORDER_ITEM_TYPE_PRODUCT, ORDER_ITEM_TYPE_STOCK_PRODUCT, ORDER_ITEM_TYPE_SERVICE_ITEM, HOME_GOOD_RECTANGULAR_TABLE_CLOTH, HOME_GOOD_CURTAIN_PANEL,
  HOME_GOOD_DUVET_COVER, HOME_GOOD_SQUARE_THROW_PILLOW, HOME_GOOD_LUMBAR_THROW_PILLOW, HOME_GOOD_THROW_BLANKET, HOME_GOOD_ROUND_TABLE_CLOTH,
  HOME_GOOD_TABLE_RUNNER, HOME_GOOD_COCKTAIL_NAPKIN, HOME_GOOD_DINNER_NAPKIN, HOME_GOOD_PLACEMAT, HOME_GOOD_STANDARD_PILLOW_SHAM,
  HOME_GOOD_EURO_PILLOW_SHAM, HOME_GOOD_SHEET_SET, HOME_GOOD_LONG_LUMBAR_THROW_PILLOW, ORDER_ITEM_TYPE_SOLID_ITEM, HOME_GOOD_TEA_TOWEL
} from '../constants/Codes';
import {SPOONFLOWER} from '../constants/Common';
import {
  DATA_FABRIC, DATA_WALLPAPER, DATA_HOME_GOOD, DATA_PRODUCT_ITEM, DATA_SERVICE_ITEM, DATA_DINING, DATA_LIVING, DATA_BEDDING, DATA_ERROR_NO_SKU,
  DATA_SOLID
} from '../constants/DataLayer';
import {DesignResponse} from '../shapes/design';
import {Merchandise} from '../shapes/merchandise';
import {OrderItemPricePerUnit} from '../shapes/pricing';

import {getSolidSize} from './formattingHelpers';
import {isNotUndefined} from './validation';


export interface DatalayerOrderItemProps {
  id: number;
  pricing: OrderItemPricePerUnit;
  analytics: {
    sku: string;
    parentSku: string;
  };
  quantity: {
    value: number;
  };
  design: DesignResponse;
  type: string;
  fabric: Record<string, React.ReactNode>;
  merchandise: Merchandise;
  sub_type: string;
}

interface Category {
  item_category: string;
  item_category2?: string;
  item_category3?: string;
}

export const getOrderItemName = (orderItem: DatalayerOrderItemProps): React.ReactNode => {
  // Fabric Name / Home Good Type
  switch (orderItem.type) {
    case ORDER_ITEM_TYPE_FABRIC_ITEM:
    case ORDER_ITEM_TYPE_UNPRINTED_ITEM:
    case ORDER_ITEM_TYPE_KIT_ITEM:
      return get(orderItem.fabric, 'type');
    case ORDER_ITEM_TYPE_WALLPAPER_ITEM:
      return isNotUndefined(get(orderItem.merchandise, 'wallpaper_item')) ?
        Translate.translate(`en.fabricShop.${orderItem.merchandise.wallpaper_item.fabric.code}`) :
        get(orderItem.fabric, 'type');
    case ORDER_ITEM_TYPE_HOME_GOOD_ITEM:
      return Translate.translate(`en.homeGood.${orderItem.merchandise.home_good_item.home_good.home_good_type.code}`);
    case ORDER_ITEM_TYPE_SOLID_ITEM:
      return isNotUndefined(get(orderItem.merchandise, 'solid_item.solid')) ?
        Translate.translate(`en.solidFabrics.${orderItem.merchandise.solid_item.solid.fabric_code}.name`) : DATA_SOLID;
    case ORDER_ITEM_TYPE_PRODUCT:
    case ORDER_ITEM_TYPE_STOCK_PRODUCT:
    case ORDER_ITEM_TYPE_SERVICE_ITEM:
      return Translate.translate(`en.orderItems.subTypes.${orderItem.sub_type}`);
    default:
      return get(orderItem.fabric, 'type');
  }
};

export const getOrderItemFabric = (orderItem: DatalayerOrderItemProps): React.ReactNode => {
  switch (orderItem.type) {
    case ORDER_ITEM_TYPE_FABRIC_ITEM:
    case ORDER_ITEM_TYPE_UNPRINTED_ITEM:
    case ORDER_ITEM_TYPE_KIT_ITEM:
      return get(orderItem.fabric, 'code');
    case ORDER_ITEM_TYPE_WALLPAPER_ITEM:
      return isNotUndefined(get(orderItem.merchandise, 'wallpaper_item')) ? orderItem.merchandise.wallpaper_item.fabric.code : get(orderItem.fabric, 'code');
    case ORDER_ITEM_TYPE_HOME_GOOD_ITEM:
      return isNotUndefined(get(orderItem.fabric, 'code')) ? orderItem.fabric.code : orderItem.merchandise.home_good_item.home_good.home_good_type.code;
    case ORDER_ITEM_TYPE_PRODUCT:
    case ORDER_ITEM_TYPE_STOCK_PRODUCT:
    case ORDER_ITEM_TYPE_SERVICE_ITEM:
      return orderItem.sub_type;
    default:
      return get(orderItem.fabric, 'code');
  }
};

export const getOrderItemVariant = (orderItem: DatalayerOrderItemProps): string | React.ReactNode | undefined => {
  // Size / Style
  switch (orderItem.type) {
    case ORDER_ITEM_TYPE_FABRIC_ITEM:
    case ORDER_ITEM_TYPE_WALLPAPER_ITEM:
      return Translate.translate(`en.cart.cart.${orderItem.fabric.size}`);
    case ORDER_ITEM_TYPE_HOME_GOOD_ITEM:
      return Translate.translate(`en.homeGood.cart.productSize.${orderItem.merchandise.home_good_item.home_good.code}`);
    case ORDER_ITEM_TYPE_SOLID_ITEM:
      return isNotUndefined(get(orderItem.merchandise, 'solid_item.width')) &&
        getSolidSize('en', orderItem.merchandise.solid_item.width, orderItem.merchandise.solid_item.length, orderItem.pricing.price_per.unit_type);
    case ORDER_ITEM_TYPE_UNPRINTED_ITEM:
    case ORDER_ITEM_TYPE_KIT_ITEM:
      return Translate.translate(`en.orderItems.subTypes.${orderItem.sub_type}`);
    default:
      // no variant for ORDER_ITEM_TYPE_PRODUCT and ORDER_ITEM_TYPE_SERVICE_ITEM
      return undefined;
  }
};

export const getOrderItemSize = (orderItem: DatalayerOrderItemProps): string | React.ReactNode | undefined => {
  switch (orderItem.type) {
    case ORDER_ITEM_TYPE_FABRIC_ITEM:
    case ORDER_ITEM_TYPE_WALLPAPER_ITEM:
      return orderItem.fabric.size;
    case ORDER_ITEM_TYPE_HOME_GOOD_ITEM:
      return orderItem.merchandise.home_good_item.home_good.code;
    case ORDER_ITEM_TYPE_UNPRINTED_ITEM:
    case ORDER_ITEM_TYPE_KIT_ITEM:
      return orderItem.sub_type;
    default:
      return undefined;
  }
};

export const getOrderItemCategory = (orderItem: DatalayerOrderItemProps): Category | undefined => {
  // Fabric, Home Good, Wallpaper, Service Item, Product Item, Solid
  switch (orderItem.type) {
    case ORDER_ITEM_TYPE_FABRIC_ITEM:
    case ORDER_ITEM_TYPE_UNPRINTED_ITEM:
    case ORDER_ITEM_TYPE_KIT_ITEM:
      return {
        item_category: DATA_FABRIC
      };
    case ORDER_ITEM_TYPE_WALLPAPER_ITEM:
      return {
        item_category: DATA_WALLPAPER
      };
    case ORDER_ITEM_TYPE_HOME_GOOD_ITEM: {
      const homeGoodType = get(orderItem.merchandise, 'home_good_item.home_good.home_good_type.code');
      const homeGoodCategory = {
        item_category: DATA_HOME_GOOD,
        item_category2: getHomeGoodDepartment(homeGoodType),
        item_category3: Translate.translate(`en.homeGood.${orderItem.merchandise.home_good_item.home_good.home_good_type.code}`),
      } as Category;

      return homeGoodCategory;
    }
    case ORDER_ITEM_TYPE_PRODUCT:
    case ORDER_ITEM_TYPE_STOCK_PRODUCT:
      return {
        item_category: DATA_PRODUCT_ITEM
      };
    case ORDER_ITEM_TYPE_SERVICE_ITEM:
      return {
        item_category: DATA_SERVICE_ITEM
      };
    case ORDER_ITEM_TYPE_SOLID_ITEM:
      return {
        item_category: DATA_SOLID
      };
    default:
      return undefined;
  }
};

export const getHomeGoodDepartment = (homeGoodType: string): string | undefined => {
  // Living, Dining, Bedding
  switch (homeGoodType) {
    case HOME_GOOD_CURTAIN_PANEL:
    case HOME_GOOD_SQUARE_THROW_PILLOW:
    case HOME_GOOD_LUMBAR_THROW_PILLOW:
    case HOME_GOOD_THROW_BLANKET:
      return DATA_LIVING;
    case HOME_GOOD_RECTANGULAR_TABLE_CLOTH:
    case HOME_GOOD_ROUND_TABLE_CLOTH:
    case HOME_GOOD_TABLE_RUNNER:
    case HOME_GOOD_COCKTAIL_NAPKIN:
    case HOME_GOOD_DINNER_NAPKIN:
    case HOME_GOOD_PLACEMAT:
    case HOME_GOOD_TEA_TOWEL:
      return DATA_DINING;
    case HOME_GOOD_DUVET_COVER:
    case HOME_GOOD_STANDARD_PILLOW_SHAM:
    case HOME_GOOD_EURO_PILLOW_SHAM:
    case HOME_GOOD_SHEET_SET:
    case HOME_GOOD_LONG_LUMBAR_THROW_PILLOW:
      return DATA_BEDDING;
    default:
      return undefined;
  }
};

export const getOrderItemDepartment = (orderItem: DatalayerOrderItemProps): string | undefined => {
  // Fabric (Solid), Wallpaper, Living, Dining, Bedding
  const homeGoodType = get(orderItem.merchandise, 'home_good_item.home_good.home_good_type.code');

  switch (orderItem.type) {
    case ORDER_ITEM_TYPE_FABRIC_ITEM:
    case ORDER_ITEM_TYPE_UNPRINTED_ITEM:
    case ORDER_ITEM_TYPE_KIT_ITEM:
    case ORDER_ITEM_TYPE_SOLID_ITEM:
      return DATA_FABRIC;
    case ORDER_ITEM_TYPE_WALLPAPER_ITEM:
      return DATA_WALLPAPER;
    case ORDER_ITEM_TYPE_HOME_GOOD_ITEM:
      return getHomeGoodDepartment(homeGoodType);
    default:
      // no department for ORDER_ITEM_TYPE_PRODUCT and ORDER_ITEM_TYPE_SERVICE_ITEM
      return undefined;
  }
};

export const orderItemProducts = (orderItem: DatalayerOrderItemProps): Record<string, unknown> => {
  const name = getOrderItemName(orderItem);
  const variant = getOrderItemVariant(orderItem);
  const item_categories = getOrderItemCategory(orderItem);
  const department = getOrderItemDepartment(orderItem);
  const color = isNotUndefined(get(orderItem.merchandise, 'solid_item.solid.color_code')) ?
    Translate.translate(`en.solidColors.${orderItem.merchandise.solid_item.solid.color_code}.name`) : undefined;

  return {
    id: orderItem.id,
    name,
    price: orderItem.pricing.raw_price_usd,
    priceUndiscounted: orderItem.pricing.raw_price_undiscounted_usd,
    localPrice: orderItem.pricing.raw_price,
    localPriceUndiscounted: orderItem.pricing.raw_price_undiscounted,
    localCurrencyCode: orderItem.pricing.currency,
    quantity: orderItem.quantity.value,
    ...item_categories,
    // not always defined
    color,
    department,
    variant,
    brand: get(orderItem.design, 'designer', SPOONFLOWER),
    dimension3: get(orderItem.design, 'id') || get(orderItem.merchandise, 'solid_item.solid.id'),
    addition: get(orderItem.merchandise, 'home_good_item.home_goods_additions[0].name'),
    productSku: get(orderItem.analytics, 'sku', DATA_ERROR_NO_SKU),
    parentSku: get(orderItem.analytics, 'parent_sku', DATA_ERROR_NO_SKU)
  };
};
