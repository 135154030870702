module.exports = {
	"metaTitle": "Request for Information | Spoonflower",
	"title": "Request for Information",
	"text": "Laws provide consumers with the right to request access or deletion of their personal information. The following information will only be used to validate and fulfill your request.",
	"haveAccount": "Already have a Spoonflower account?",
	"login": "Log in here.",
	"residentLinkLabel": "What if I am an agent of a customer or site user?",
	"submit": "Submit Request",
	"emailField": {
		"label": "Personal Details",
		"placeHolder": "Email Address"
	},
	"guestDeletion": {
		"metaTitle": "Request for Information | Spoonflower",
		"title": "Request for Deletion",
		"emailFieldText": "The following information will only be used to validate and fulfill your request",
		"checkboxError": "Choose an option below",
		"checkboxLabel": "Please make a selection below."
	},
	"requestRadio": {
		"label": "Please tell us more about your request. You can choose one request at a time.",
		"tooltipDescription": "Spoonflower may share customer personal information in order to make ads more relevant to you. Spoonflower never provides your personal information to third parties in exchange or money.",
		"optionLabel1": "Delete Account Request",
		"optionDescription1": "I want to delete the personal information that Spoonflower has collected or shared about me or my minor child. I understand that my Spoonflower account, including all orders, preferences and communications will be deleted.",
		"optionLabel2": "Information Access Request",
		"optionDescription2": "I want to access the personal information that Spoonflower has collected or shared about me or my minor child.",
		"optionLabel3": "Do Not Sell or Share Request",
		"optionDescription3": "I want to prevent sale or share of personal information for targeted advertising for me or my minor child.",
		"errorMessage": "Choose an option below"
	},
	"notAResidentDeletion": "request deletion of your account",
	"requestSubmitted": {
		"delete": {
			"request": "Delete Account Request Submitted",
			"description": "Thank you for your request to delete the personal information that Spoonflower has collected about you. You will need to take further action to complete this request. Please follow the instructions in the email we sent to",
			"footer": "Reach out to privacy@spoonflower.com if you have any further questions."
		},
		"information": {
			"request": "Information Access Request Submitted",
			"description": "Thank you for your request to know the personal information that Spoonflower has collected about you. You will need to take further action to complete this request. Please follow the instructions in the email we sent to",
			"footer": "Reach out to privacy@spoonflower.com if you have any further questions."
		},
		"noSell": {
			"request": "Do Not Sell or Share Request Submitted",
			"description": "Your request to prevent sale or share of personal information for targeted advertising has been received. No further action is needed from you.",
			"footer": "We at Spoonflower respect your privacy. Please note that this will opt you out of advertising cookies."
		}
	},
	"representResident": {
		"title": "If you are an authorized representative who wants to make a data privacy request",
		"text": "Spoonflower will process requests to know, requests to delete and requests to not sell or share personal   information submitted by authorized agents if we can verify that the agent has permission from the subject of the request. There are two methods of confirming this permission.",
		"text2": "Please indicate the specific request: request to know, request to delete, or request to not sell or share the subject’s data.",
		"footerText": "We will begin processing the request once we have adequately verified the request subject’s identity and the written permission.",
		"address1": "Shutterfly Privacy Office, Legal Department",
		"address2": "ATTN: Spoonflower Data Subject Request – Agent Power of Attorney",
		"address3": "11000 Viking Drive, Suite 400W",
		"address4": "Eden Prairie, MN 55344",
		"li1": {
			"title": "1. Power of Attorney",
			"text": "If the agent has an executed Power of Attorney from the subject of the equest, the agent can submit this Power of Attorney document via mail at:",
			"description": "The Power of Attorney document can also be provided via e-mail with the subject line “Spoonflower Data Subject Request – Agent Power of Attorney” to privacy@spooflower.com.",
			"footerText": "Please indicate the specific request: request to know, request to delete, or request to not sell or share the personal information of the data subject."
		},
		"li2": {
			"title": "2. Written Permission and Subject Verification",
			"text": "If the agent does not have an executed Power of Attorney, the agent will need to submit proof that the  subject has given written permission to the Agent to submit the request. The request subject will also need to      separately verify their own identity. The agent can submit proof of written permission via mail at:",
			"description": "The proof of written permission can also be provided via e-mail with the subject line “Spoonflower Data Subject Request – Agent Permission” to",
			"footerText": "Once we have received the proof of written permission, we will send an e-mail to the subject of the request to verify their identity. If the request subject has an account, they will be asked to log in. If they do not, they will be asked to complete a web form with information that will help us verify their identity."
		}
	}
};