import React from 'react'

export function HeartIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}): JSX.Element {
  return (
    <svg
      viewBox='0 0 25 21'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M21.437 1.36173C18.357 -0.738274 14.5537 0.241726 12.5003 2.64506C10.447 0.241726 6.64366 -0.74994 3.56366 1.36173C1.93033 2.48173 0.903663 4.37173 0.833663 6.36673C0.67033 10.8934 4.68366 14.5217 10.8087 20.0867L10.9253 20.1917C11.812 20.9967 13.177 20.9967 14.0637 20.1801L14.192 20.0634C20.317 14.5101 24.3187 10.8817 24.167 6.35506C24.097 4.37173 23.0703 2.48173 21.437 1.36173ZM12.617 18.3484L12.5003 18.4651L12.3837 18.3484C6.83033 13.3201 3.167 9.99506 3.167 6.62339C3.167 4.29006 4.917 2.54006 7.25033 2.54006C9.047 2.54006 10.797 3.69506 11.4153 5.29339H13.597C14.2037 3.69506 15.9537 2.54006 17.7503 2.54006C20.0837 2.54006 21.8337 4.29006 21.8337 6.62339C21.8337 9.99506 18.1703 13.3201 12.617 18.3484Z'
      ></path>
    </svg>
  )
}
