import { MenuLink } from '../Menu'

export const about: MenuLink[] = [
  { key: 'About Us', url: '/en/about' },
  { key: 'How It Works', url: '/en/about/how-it-works' },
  {
    key: 'Purpose & Impact',
    url: '/en/about/purpose-and-impact',
  },
]
