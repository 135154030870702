import { MenuLink } from '../Menu'

export const artist: MenuLink[] = [
  { key: 'Upload a Design', url: '/artists/designs/new' },
  { key: 'Design Challenges', url: '/design-challenge' },
  { key: 'Selling & Commissions', url: '/en/sell' },
  { key: 'Seller Agreement', url: '/seller_agreement' },
  {
    key: 'Designing FAQs',
    url: 'https://support.spoonflower.com/hc/en-us/sections/200793490-Creating',
  },
  {
    key: 'Selling FAQs',
    url: 'https://support.spoonflower.com/hc/en-us/sections/200793480-Selling',
  },
  {
    key: 'Artist Tutorials',
    url: 'https://blog.spoonflower.com/category/tutorials/design-tutorials/',
  },
]
