import * as React from 'react'
import styles from './SpoonflowerLogo.module.scss'

export function SpoonflowerLogo({ mobile }: { mobile?: boolean }) {
  return (
    <a
      href={'/'}
      className={`${styles.wrapper}  ${mobile ? styles.mobile : ''}`}
    >
      <div className={styles.logo}>
        <img
          src='https://cdn.spoonflower.com/baerlauch/production/images/logo.svg'
          alt='spoonflower-logo'
        />
      </div>
    </a>
  )
}
