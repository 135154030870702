import React, {SyntheticEvent} from 'react';

import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {HOSTNAME_PRODUCTION, HOSTNAME_STAGING} from '../../../hostname/constants';
import {setPlaceholderImage} from '../../../utils/design';
import LazyImage from '../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import './_design-item.scss';


const ProductTypeToURLMap = {
  'FABRIC': 'fabric',
  'WALLPAPER': 'wallpaper',
  'HOME_GOOD': 'home-good',
  'HOME_GOOD_SQUARE_THROW_PILLOW': 'squareThrowPillows',
  'HOME_GOOD_RECTANGULAR_TABLE_CLOTH': 'rectangularTablecloths',
  'HOME_GOOD_ROUND_TABLE_CLOTH': 'roundTablecloths',
  'HOME_GOOD_TABLE_RUNNER': 'tableRunners',
  'HOME_GOOD_PLACEMAT': 'placemats',
  'HOME_GOOD_COCKTAIL_NAPKIN': 'cocktailNapkins',
  'HOME_GOOD_DINNER_NAPKIN': 'dinnerNapkins',
  'HOME_GOOD_TEA_TOWEL': 'teaTowels',
  'HOME_GOOD_DUVET_COVER': 'duvetCovers',
  'HOME_GOOD_SHEET_SET': 'sheetSets',
  'HOME_GOOD_STANDARD_PILLOW_SHAM': 'standardPillowShams',
  'HOME_GOOD_EURO_PILLOW_SHAM': 'euroPillowShams',
  'HOME_GOOD_LUMBAR_THROW_PILLOW': 'lumbarThrowPillows',
  'HOME_GOOD_THROW_BLANKET': 'throwBlankets',
  'HOME_GOOD_CURTAIN_PANEL': 'curtains',
  'HOME_GOOD_LONG_LUMBAR_THROW_PILLOW': 'longLumbarThrowPillow',
};

export interface DesignItemPDPSeeMoreProps {
  productType: string;
  designId: number;
  designTitle: string;
  imageUrl: string;
}

const DesignItemPDPSeeMore = ({imageUrl, designTitle, productType, designId}: DesignItemPDPSeeMoreProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  const moreLikeThisURL = `/en/designs/${designId}/similar?on=${ProductTypeToURLMap[productType as keyof typeof ProductTypeToURLMap]}`;

  const onErrorFunc = (e: SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, imagesHost);
  const content = (
    <div className='see-more-like-this-container'>
      <LazyImage
        aspectRatio={true}
        className='item-pic'
        src={imageUrl}
        alt={designTitle}
        itemProp='image'
        onError={onErrorFunc}
        height='294'
        width='294'
        data-testid='design-item-image'
      />
      <div
        data-testid='see-more-like-this'
        className='see-more-like-this'
      >
        More Like This
      </div>
    </div>);

  return (
    <div
      className='b-design-item x-condensed'
      itemProp='isRelatedTo'
      itemScope
      itemType='https://schema.org/Product'
    >
      <LinkWrapper hrefValue={moreLikeThisURL} contents={content} title={'See more like this'} />
    </div>
  );
};

export default DesignItemPDPSeeMore;
