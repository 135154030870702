import { MenuLink } from '../Menu'
import { bedding } from './bedding'
import { dining } from './dining'
import { living } from './living'

export const decor: MenuLink[] = [
  { key: 'Explore Home Decor', url: '/en/home-decor' },
  { key: 'Bedding', url: '/en/home-decor/bedding', childrenItems: bedding },
  {
    key: 'Dining',
    url: '/en/home-decor/dining',
    childrenItems: dining,
  },
  {
    key: 'Living & Decor',
    url: '/en/home-decor/living-decor',
    childrenItems: living,
  },
]
