import * as React from 'react'
import { Dropdown } from '../../../Dropdown/Dropdown'
import { User } from '../../Header'
import { CirclePlusIcon } from './../../../../theme/icons/CirclePlusIcon'
import { PersonIcon } from './../../../../theme/icons/PersonIcon'
import styles from './ProfileMenu.module.scss'

export function ProfileMenu({ user }: { user: User }) {
  return (
    <div key='profile-button' className={styles.profileWrapper}>
      <Dropdown
        buttonLabel={
          <PersonIcon aria-label='profile' data-testid='profile-button' />
        }
        buttonStyle={styles.userNavigation}
        id={'profile-dropdown'}
        ariaLabel='profile'
      >
        <div
          className={styles.profile}
          aria-labelledby='profile'
          key='profile-menu'
          data-testid='profile-menu'
        >
          <a
            key='my-dashboard'
            aria-label='my dashboard'
            href='/studio'
            className={styles.dashboard}
          >
            <div className={styles.username} key={user.screen_name}>
              {user.screen_name}
            </div>
            <div className={styles.dashboardLink} key='my-dashboard'>
              My Dashboard
            </div>
          </a>
          <a
            href={`/account/${user.id}?sub_action=orders`}
            className={styles.link}
          >
            Orders
          </a>
          <a
            href={`/account/${user.id}?sub_action=settings`}
            className={styles.link}
          >
            Account Settings
          </a>
          <a href={`/favorites/${user.screen_name}`} className={styles.link}>
            Favorites
          </a>
          <a
            href={`/profiles/${user.screen_name}/collections?filter=designed`}
            className={styles.link}
          >
            Collections
          </a>
          <div className={styles.divider} />
          <a href={`/designs`} className={styles.link}>
            Design Library
          </a>
          <a href={`/profiles/${user.screen_name}`} className={styles.link}>
            View My Shop
          </a>
          <div key='upload-design' className={styles.designItem}>
            <a href={`/artists/designs/new`} className={styles.designButton}>
              <CirclePlusIcon /> UPLOAD A DESIGN
            </a>
          </div>
          <div className={styles.divider} />
          <a href={`/userhome/${user.id}`} className={styles.link}>
            Notifications
          </a>
          <a href={`/messages`} className={styles.link}>
            Messages
          </a>
          <a href={`/design-challenge/me/voting`} className={styles.link}>
            My Design Challenges
          </a>
          <div className={styles.divider} />
          <a href={`/logout`} className={styles.link}>
            Logout
          </a>
        </div>
      </Dropdown>
    </div>
  )
}
