import React from 'react'
import { IconProps } from '.'

export function CirclePlusIcon({
  width = 17,
  height = 17,
  color = 'white',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      focusable='false'
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m5 11h-4v4h-2v-4H7v-2h4V7h2v4h4z' />
    </svg>
  )
}
