import React from 'react'
import { IconProps } from '.'

export function ChevronLeftIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 24 24'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M 13.41 7.41 L 12 6 L 6.59 11.41 L 6 12 L 6.59 12.59 L 12 18 L 13.41 16.59 L 8.83 12 L 13.41 7.41 z'
      ></path>
    </svg>
  )
}
