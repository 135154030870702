import * as React from 'react'
import { IconProps } from '.'

export function TikTokIcon({
  height = 25,
  width = 25,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 512 512'
      focusable='false'
      aria-hidden
      role='img'
      {...props}
    >
      <path d='M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0Zm129.62 232.382c-27.184 0-53.634-8.822-74-23.75l-.162 101.5a92.457 92.457 0 1 1-80.178-91.721v49.845a43.657 43.657 0 1 0 31.288 41.876V109.333h51.275a71.773 71.773 0 0 0 71.774 71.773Z' />
    </svg>
  )
}
