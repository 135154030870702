import React from 'react'

export function MinusIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...props
}): JSX.Element {
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      focusable='false'
      width={width}
      height={height}
      fill='none'
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M4.64286 10.75C4.45714 10.75 4.30357 10.6674 4.18214 10.5021C4.06071 10.3368 4 10.1278 4 9.875C4 9.62222 4.06071 9.41319 4.18214 9.24792C4.30357 9.08264 4.45714 9 4.64286 9H15.3571C15.5429 9 15.6964 9.08264 15.8179 9.24792C15.9393 9.41319 16 9.62222 16 9.875C16 10.1278 15.9393 10.3368 15.8179 10.5021C15.6964 10.6674 15.5429 10.75 15.3571 10.75H4.64286Z'
        fill={color}
      />
    </svg>
  )
}
