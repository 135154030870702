import React from 'react'
import { IconProps } from '.'

export function TruckIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 20 20'
      focusable='false'
      width={width}
      height={height}
      fill='none'
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M16.2497 6.66671H14.1663V5.00004C14.1663 4.08337 13.4163 3.33337 12.4997 3.33337H2.49967C1.58301 3.33337 0.833008 4.08337 0.833008 5.00004V12.5C0.833008 13.4167 1.58301 14.1667 2.49967 14.1667C2.49967 15.55 3.61634 16.6667 4.99967 16.6667C6.38301 16.6667 7.49967 15.55 7.49967 14.1667H12.4997C12.4997 15.55 13.6163 16.6667 14.9997 16.6667C16.383 16.6667 17.4997 15.55 17.4997 14.1667H18.333C18.7913 14.1667 19.1663 13.7917 19.1663 13.3334V10.5584C19.1663 10.2 19.0497 9.85004 18.833 9.55837L16.9163 7.00004C16.758 6.79171 16.508 6.66671 16.2497 6.66671ZM4.99967 15C4.54134 15 4.16634 14.625 4.16634 14.1667C4.16634 13.7084 4.54134 13.3334 4.99967 13.3334C5.45801 13.3334 5.83301 13.7084 5.83301 14.1667C5.83301 14.625 5.45801 15 4.99967 15ZM16.2497 7.91671L17.883 10H14.1663V7.91671H16.2497ZM14.9997 15C14.5413 15 14.1663 14.625 14.1663 14.1667C14.1663 13.7084 14.5413 13.3334 14.9997 13.3334C15.458 13.3334 15.833 13.7084 15.833 14.1667C15.833 14.625 15.458 15 14.9997 15Z'
        fill={color}
      />
    </svg>
  )
}
