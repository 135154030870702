import React from 'react'
import { IconProps } from '.'

export function ShoppingCartIcon({
  width = 18,
  height = 18,
  color = '#5f9da4',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 -50 1024 1024'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      style={{ transform: 'rotate(180deg)  scaleX(-1)' }}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M477.511-0.069c-22.439-38.221-64.34-63.931-112.326-63.931-71.643 0-129.721 57.308-129.721 128 0 40.108 18.695 75.907 47.955 99.376l-132.1 668.624h-87.32c-35.346 0-64 28.654-64 64s28.654 64 64 64h158.77c27.672 0 52.21-17.785 60.822-44.083l27.512-83.917h648.88c35.346 0 64-28.654 64-64 0-4.138-0.401-8.265-1.198-12.326l-68.214-347.568c-9.222-46.988-43.9-84.9-89.882-98.263l-445.448-129.453c24.498-11.096 44.89-29.502 58.307-52.386h253.272c22.43 38.257 64.351 63.995 112.364 63.995 71.643 0 129.721-57.308 129.721-128s-58.078-128-129.721-128c-47.986 0-89.887 25.71-112.326 63.931z'
        fill={color}
      />
    </svg>
  )
}
