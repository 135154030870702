import { MenuLink } from '../Menu'

export const living: MenuLink[] = [
  { key: 'All Living & Decor', url: '/en/home-decor/living-decor' },
  {
    key: 'Square Throw Pillows',
    url: '/en/home-decor/living-decor/square-throw-pillow',
  },
  {
    key: 'Lumbar Throw Pillows',
    url: '/en/home-decor/living-decor/lumbar-throw-pillow',
  },
  { key: 'Throw Blankets', url: '/en/home-decor/living-decor/throw-blanket' },
  { key: 'Curtains', url: '/en/home-decor/living-decor/curtains' },
]
