import { MenuLink } from '../Menu'
import { project } from './project'
import { room } from './room'
import { style } from './style'

export const inspiration: MenuLink[] = [
  {
    key: 'Inspiration',
    url: '/en/inspiration',
  },
  {
    key: 'By Room',
    url: '/en/inspiration/rooms',
    childrenItems: room,
  },
  {
    key: 'By Style',
    url: '/en/inspiration/styles',
    childrenItems: style,
  },
  {
    key: 'By Fabric Project',
    childrenItems: project,
  },
  {
    key: 'Collaborations',
    url: '/en/inspiration/collaborations',
  },
  {
    key: 'Design Challenge Winners',
    url: '/design-challenge/past',
  },
  {
    key: 'Blog',
    url: 'https://blog.spoonflower.com/',
  },
  {
    key: 'Gift Ideas',
    url: '/en/gift-ideas',
  },
]
