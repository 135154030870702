import * as React from 'react'
import { useEffect, useState } from 'react'
import { CloseIcon } from '../../../theme/icons/CloseIcon'
import { Drawer } from '../../Drawer/Drawer'
import { FooterProps } from '../Footer'
import styles from './UpdatePreferences.module.scss'

type MeasurementSystem = 'IMPERIAL' | 'METRIC'

const IMPERIAL: MeasurementSystem = 'IMPERIAL'
const METRIC: MeasurementSystem = 'METRIC'

const measurementSystems: Record<MeasurementSystem, string> = {
  [IMPERIAL]: 'in, ft, yd',
  [METRIC]: 'cm, m',
}

export const UpdatePreferences = ({ user, isProduction }: FooterProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const [selected, setSelected] = useState(
    user?.preferences?.measurement_system === 'METRIC'
      ? measurementSystems.METRIC
      : measurementSystems.IMPERIAL
  )

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const apiUrl = `https://${
      isProduction ? '' : 'staging-'
    }api-gateway.spoonflower.com/alpenrose/user/me/preferences`
    try {
      await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          preferences: {
            measurement_system:
              selected === measurementSystems.METRIC ? METRIC : IMPERIAL,
          },
        }),
      })
      toggleDrawer()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setSelected(
      user?.preferences?.measurement_system === 'METRIC'
        ? measurementSystems.METRIC
        : measurementSystems.IMPERIAL
    )
  }, [user])

  return (
    <>
      <button
        onClick={toggleDrawer}
        data-testid='button-updatePreferences'
        className={styles.updatePreferences}
      >
        {selected}
      </button>
      <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer}>
        <div className={styles.container}>
          <div className={styles.topBar}>
            <button
              className={styles.closeButton}
              onClick={toggleDrawer}
              aria-label='close'
            >
              <CloseIcon height={12} width={12} />
            </button>
          </div>
          <form className={styles.content} onSubmit={handleSubmit}>
            <div>
              <div className={styles.title}>Update your preferences</div>
              <label className={styles.label}>MEASUREMENT</label>
              <div className={styles.selector}>
                <label
                  className={
                    selected === measurementSystems.IMPERIAL
                      ? styles.selected
                      : ''
                  }
                >
                  <div>{measurementSystems[IMPERIAL]}</div>
                  <input
                    type='radio'
                    name='measurementSystem'
                    value={IMPERIAL}
                    checked={selected === measurementSystems.IMPERIAL}
                    onChange={() => setSelected(measurementSystems.IMPERIAL)}
                  />
                </label>
                <label
                  className={
                    selected === measurementSystems.METRIC
                      ? styles.selected
                      : ''
                  }
                >
                  <div>{measurementSystems[METRIC]}</div>
                  <input
                    type='radio'
                    name='measurementSystem'
                    value={METRIC}
                    checked={selected === measurementSystems.METRIC}
                    onChange={() => setSelected(measurementSystems.METRIC)}
                  />
                </label>
              </div>
            </div>
            <div className={styles.bottomBar}>
              <button
                type='button'
                onClick={toggleDrawer}
                data-testid='button-cancel-preferences'
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button
                type='submit'
                data-testid='button-update-preferences'
                className={styles.updateButton}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  )
}
