import { MenuLink } from '../Menu'

export const bedding: MenuLink[] = [
  { key: 'All Bedding', url: '/en/home-decor/bedding' },
  { key: 'Duvet Covers', url: '/en/home-decor/bedding/duvet-cover' },
  { key: 'Sheet Sets', url: '/en/home-decor/bedding/sheet-set' },
  {
    key: 'Standard Pillow Shams',
    url: '/en/home-decor/bedding/standard-pillow-sham',
  },
  {
    key: 'Euro Pillow Shams',
    url: '/en/home-decor/bedding/euro-pillow-sham',
  },
  {
    key: 'Extra Long Lumbar Throw Pillow',
    url: '/en/home-decor/bedding/extra-long-lumbar-throw-pillow',
  },
]
