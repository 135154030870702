import * as React from 'react'
import { useState } from 'react'
import { Dropdown } from '../../Dropdown/Dropdown'
import { ChevronLeftIcon } from './../../../theme/icons/ChevronLeftIcon'
import { ChevronRightIcon } from './../../../theme/icons/ChevronRightIcon'
import styles from './Menu.module.scss'

export type MenuLink = {
  key: string
  url?: string
  isNew?: boolean
  childrenItems?: MenuLink[]
}

type SubMenuProps = {
  title: string
  childrenItems: MenuLink[]
  url: string
}

export function Menu({ title, childrenItems }: SubMenuProps) {
  const [menuStack, setMenuStack] = useState<MenuLink[][]>([childrenItems])
  const [titleStack, setTitleStack] = useState<string[]>([])

  const currentMenu = menuStack[menuStack.length - 1]

  const handleItemClick = (item: MenuLink) => {
    if (item.childrenItems) {
      setMenuStack([...menuStack, item.childrenItems])
      setTitleStack([...titleStack, item.key])
    }
  }

  const handleBackClick = () => {
    if (menuStack.length > 1) {
      setMenuStack(menuStack.slice(0, -1))
      setTitleStack(titleStack.slice(0, -1))
    }
  }

  return (
    <li className={styles.menuWrapper}>
      <Dropdown
        buttonLabel={title}
        buttonStyle={styles.menuButton}
        dropdownStyle={styles.subMenuWrapper}
        id={`${title}-dropdown`}
        onClose={() => {
          setMenuStack([childrenItems])
          setTitleStack([])
        }}
      >
        {currentMenu !== childrenItems && (
          <button
            className={styles.desktopStatus}
            onClick={handleBackClick}
            tabIndex={0}
          >
            <ChevronLeftIcon /> {titleStack[titleStack.length - 1]}:
          </button>
        )}
        {currentMenu.map((item) => {
          return (
            <li
              role='presentation'
              className={styles.itemWrapper}
              key={item.key}
            >
              {item.childrenItems ? (
                <button
                  role='menuitem'
                  key={item.key}
                  className={styles.category}
                  onClick={() => handleItemClick(item)}
                >
                  <>{item.key}</> <ChevronRightIcon />
                </button>
              ) : (
                <a
                  role='menuitem'
                  href={item.url}
                  key={item.key}
                  className={styles.item}
                >
                  {item.key}
                  {item.isNew && <span className={styles.itemLabel}>New</span>}
                </a>
              )}
            </li>
          )
        })}
      </Dropdown>
    </li>
  )
}
