import { MenuLink } from '../Menu'
import { room } from './room'
import { style } from './style'

export const wallpaper: MenuLink[] = [
  { key: 'Explore Wallpaper', url: '/en/wallpaper' },
  { key: 'Shop All Wallpaper Designs', url: '/en/shop?on=wallpaper' },
  {
    key: 'By Room',
    url: '/en/inspiration/rooms',
    childrenItems: room,
  },
  {
    key: 'By Style',
    url: '/en/inspiration/styles',
    childrenItems: style,
  },
  {
    key: 'Peel and Stick Wallpaper',
    url: '/en/wallpaper/types/peel-and-stick-wallpaper',
  },
  {
    key: 'Pre-Pasted Wallpaper',
    url: '/en/wallpaper/types/pre-pasted-wallpaper',
  },
  {
    key: 'Non-Pasted Wallpaper',
    url: '/en/wallpaper/types/non-pasted-wallpaper',
  },
  {
    key: 'Grasscloth Wallpaper',
    url: '/en/wallpaper/types/grasscloth-wallpaper',
  },
  {
    key: 'Metallic Wallpaper',
    url: '/en/wallpaper/types/metallic-wallpaper',
  },
  {
    key: 'Vinyl Wallpaper',
    url: '/en/wallpaper/types/vinyl-wallpaper',
    isNew: true,
  },
]
