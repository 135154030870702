import { MenuLink } from '../Menu'
import { about } from './about'
import { artist } from './artist'
import { decor } from './decor'
import { fabric } from './fabric'
import { inspiration } from './inspiration'
import { trade } from './trade'
import { wallpaper } from './wallpaper'

export const mainMenu: MenuLink[] = [
  {
    key: 'Inspiration',
    childrenItems: inspiration,
    url: '/en/inspiration',
  },
  {
    key: 'Fabric',
    childrenItems: fabric,
    url: '/en/fabric',
  },
  {
    key: 'Wallpaper',
    childrenItems: wallpaper,
    url: '/en/wallpaper',
  },
  {
    key: 'Home Decor',
    childrenItems: decor,
    url: '/en/home-decor',
  },
  {
    key: 'Trade Program',
    childrenItems: trade,
    url: '/en/trade',
  },
  {
    key: 'Artist Corner',
    childrenItems: artist,
    url: 'artists/designs/new',
  },
  {
    key: 'About',
    childrenItems: about,
    url: '/en/about',
  },
]
