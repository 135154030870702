import { MenuLink } from '../Menu'

export const style: MenuLink[] = [
  {
    key: 'All Styles',
    url: '/en/inspiration/styles',
  },
  {
    key: 'Bohemian',
    url: '/en/inspiration/styles/bohemian/bohemian-wallpaper',
  },
  {
    key: 'Coastal',
    url: '/en/inspiration/styles/coastal/coastal-wallpaper',
  },
  {
    key: 'Colorful',
    url: '/en/inspiration/styles/colorful/colorful-wallpaper',
  },
  {
    key: 'Glam',
    url: '/en/inspiration/styles/glam/glam-wallpaper',
  },
  {
    key: 'Heritage Revival',
    url: '/en/inspiration/styles/heritage-revival/heritage-revival-wallpaper',
  },
  {
    key: 'Japandi Farmhouse',
    url: '/en/inspiration/styles/japandi/japandi-wallpaper',
  },
  {
    key: 'Minimalist',
    url: '/en/inspiration/styles/minimalist/minimalist-wallpaper',
  },
  {
    key: 'Preppy',
    url: '/en/inspiration/styles/preppy/preppy-wallpaper',
  },
]
