import * as React from 'react'
import { IconProps } from '.'

export function PinterestIcon({
  width = 25,
  height = 25,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 512 512'
      focusable='false'
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M256.612 13.686C119.557 13.686 8.2 124.223 8.2 261.489c0 137.259 111.357 247.796 248.412 247.796S503.8 398.747 503.8 261.489c0-137.266-110.133-247.803-247.188-247.803zm19.579 314.61c-18.355-1.217-25.698-10.93-40.382-19.435-7.342 41.296-17.132 80.165-45.277 100.817-8.566-61.948 12.237-108.104 22.027-157.91-17.132-27.938 2.447-86.242 37.934-71.668 44.054 17.008-37.934 106.894 17.132 117.824 58.738 12.148 81.988-100.816 46.501-137.259-52.62-52.233-151.74-1.216-139.502 74.095 3.67 18.225 22.027 24.295 8.566 49.807-34.264-7.287-44.054-34.016-42.83-68.025 2.447-57.093 51.395-97.18 101.567-103.25 62.409-7.287 121.147 23.078 128.489 81.382 9.79 66.815-28.145 138.483-94.225 133.622z'
        fillRule='evenodd'
      />
    </svg>
  )
}
