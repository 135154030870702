import React from 'react'
import { IconProps } from '.'
export function ChevronDownIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 24 24'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'
      ></path>
    </svg>
  )
}
