import React from 'react'
import { IconProps } from '.'

export function CheckIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 24 24'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M8.80001 15.905L5.30001 12.405C4.91001 12.015 4.29001 12.015 3.90001 12.405C3.51001 12.795 3.51001 13.415 3.90001 13.805L8.09001 17.995C8.48001 18.385 9.11001 18.385 9.50001 17.995L20.1 7.40502C20.49 7.01502 20.49 6.39502 20.1 6.00502C19.71 5.61502 19.09 5.61502 18.7 6.00502L8.80001 15.905Z'
        fill={color}
      />
    </svg>
  )
}
