import { MenuLink } from '../Menu'
import { colors } from './colors'
import { project } from './project'

export const fabric: MenuLink[] = [
  { key: 'Explore Fabric', url: '/en/fabric' },
  { key: 'Shop All Fabric Designs', url: '/en/shop?on=fabric' },
  { key: 'By Color', childrenItems: colors },
  { key: 'By Project', childrenItems: project },
  { key: 'Fabric Types', url: '/en/fabric-shop' },
  { key: 'Fill-A-Yard ®', url: '/fill-a-yard' },
]
