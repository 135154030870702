/* eslint-disable import/order */
import './../../theme/_main.scss'
/* eslint-enable import/order */
import * as React from 'react'
import { useMemo } from 'react'
import { User } from './../../index'
import { ArrowUpIcon } from './../../theme/icons/ArrowUpIcon'
import { ChatIcon } from './../../theme/icons/ChatIcon'
import { EmailIcon } from './../../theme/icons/EmailIcon'
import { FacebookIcon } from './../../theme/icons/FacebookIcon'
import { InstagramIcon } from './../../theme/icons/InstagramIcon'
import { PinterestIcon } from './../../theme/icons/PinterestIcon'
import { QuestionIcon } from './../../theme/icons/QuestionIcon'
import { TikTokIcon } from './../../theme/icons/TikTokIcon'
import { YouTubeIcon } from './../../theme/icons/YouTubeIcon'
import { DoNotSellDrawer } from './DoNotSell/DoNotSellDrawer'
import styles from './Footer.module.scss'
import { UpdatePreferences } from './UpdatePreferences/UpdatePreferences'

export type UserBasicInfo = Pick<User, 'id' | 'screen_name' | 'preferences'>
export interface FooterProps {
  user?: User
  isProduction?: boolean
}

declare global {
  interface Window {
    zE: (command: string, action: string) => void
    OneTrust?: {
      UpdateConsent: (category: string, value: string) => void
      ToggleInfoDisplay: () => void
    }
  }
}

function handleOpenChat() {
  if (typeof window.zE === 'function') {
    window.zE('messenger', 'open')
  } else {
    console.error('window properties not available.')
  }
}

function handleCookieDrawer() {
  window.OneTrust?.ToggleInfoDisplay()
}

const socialMediaIcons = [
  [InstagramIcon, 'https://www.instagram.com/spoonflower', 'instagram'],
  [FacebookIcon, 'https://www.facebook.com/Spoonflower', 'facebook'],
  [PinterestIcon, 'https://www.pinterest.com/spoonflower', 'pinterest'],
  [YouTubeIcon, 'https://www.youtube.com/user/spoonflowerfabric', 'youtube'],
  [TikTokIcon, 'https://www.tiktok.com/@spoonflower_official', 'tiktok'],
]

export const Footer = ({ user, isProduction }: FooterProps) => {
  const currentYear = useMemo(() => new Date().getFullYear(), [])
  return (
    <footer className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.innerGrid}>
          <div className={styles.leftGrid}>
            <h2 className={styles.helpHeading}>Need Help?</h2>
            <ul className={styles.helpList}>
              <li key='help' className={styles.helpItem}>
                <a
                  href={'https://support.spoonflower.com'}
                  data-testid='link-help-center'
                >
                  <QuestionIcon aria-label='Help Center' />
                  <span>Help Center</span>
                </a>
              </li>
              <li key='chat' className={styles.helpItem}>
                <button
                  className='zendesk-live-chat'
                  data-testid='button-chat'
                  onClick={handleOpenChat}
                >
                  <ChatIcon aria-label='Chat' />
                  <span>Chat</span>
                </button>
              </li>
              <li key='submit-message' className={styles.helpItem}>
                <a
                  href={
                    'https://support.spoonflower.com/hc/en-us/requests/new?ticket_form_id=360001159372'
                  }
                  data-testid='link-submit-message'
                >
                  <EmailIcon aria-label='Submit a Message' />
                  <span>Submit a Message</span>
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.rightGrid}>
            <div className={styles.rightGridInner}>
              <div className={styles.aboutGrid}>
                <h2 className={styles.footerHeading}>Our Company</h2>
                <ul className={styles.footerList}>
                  <li key='about-us'>
                    <a href={'/en/about'} data-testid='link-about'>
                      About Us
                    </a>
                  </li>
                  <li key='careers'>
                    <a
                      href={'https://jobs.spoonflower.com/work-at-spoonflower'}
                      data-testid='link-careers'
                    >
                      Careers
                    </a>
                  </li>
                  <li key='plus-membership'>
                    <a
                      href={'/en/plus-membership'}
                      data-testid='link-plus-membership'
                    >
                      Plus Membership
                    </a>
                  </li>
                  <li key='subscribe'>
                    <a href={'/en/subscribe'} data-testid='link-subscribe'>
                      Subscribe to Our Newsletter
                    </a>
                  </li>
                  <li key='link-index'>
                    <a href={'/catalog/pt/index'} data-testid='link-index'>
                      Index
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.policiesGrid}>
                <h2 className={styles.footerHeading}>Policies</h2>
                <ul className={styles.footerList}>
                  <li key='terms-of-service'>
                    <a href={'/terms'} data-testid='link-terms'>
                      Terms of Service
                    </a>
                  </li>
                  <li key='privacy-notice'>
                    <a href={'/en/privacy'} data-testid='link-privacy-notice'>
                      Privacy Notice
                    </a>
                  </li>
                  <li key='accessibility-statement'>
                    <a
                      href={'/en/accessibility-statement'}
                      data-testid='link-accessibility'
                    >
                      Accessibility Statement
                    </a>
                  </li>
                  <li key='manage-cookies'>
                    <button
                      onClick={handleCookieDrawer}
                      data-testid='button-cookies'
                    >
                      Manage Cookies
                    </button>
                  </li>
                  {user && (
                    <li key='data-privacy'>
                      <DoNotSellDrawer user={user} />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomWrapper}>
          <div className={styles.bottomUpper}>
            <ul className={styles.socialList}>
              {socialMediaIcons.map(([Icon, path, id]) => (
                <li key={id as string}>
                  <a
                    href={path as string}
                    aria-label={`Go to Spoonflower ${id}`}
                    data-testid={`link-${id}`}
                  >
                    <Icon aria-hidden />
                  </a>
                </li>
              ))}
            </ul>
            <div className={styles.bottomLower}>
              {user && typeof isProduction !== 'undefined' && (
                <UpdatePreferences user={user} isProduction={isProduction} />
              )}
              <button
                className={styles.topButton}
                data-testid='button-top'
                onClick={() => {
                  document.body.scrollTop = 0 // Safari
                  document.documentElement.scrollTop = 0 // Chrome, Firefox
                }}
              >
                <ArrowUpIcon aria-label='Top of page' /> Top
              </button>
            </div>
          </div>
          <div className={styles.copyrightWrapper}>
            <span>© 2008-{currentYear} Spoonflower Inc.</span>
          </div>
        </div>
      </div>
    </footer>
  )
}
