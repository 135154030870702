import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { SearchIcon } from './../../../theme/icons/SearchIcon'
import styles from './Search.module.scss'

type Data<T extends Record<string, unknown>> = T & {
  event: string
}
declare global {
  interface Window {
    dataLayer?: {
      push: <T extends Record<string, unknown>>(data: Data<T>) => void
    }
  }
}

const searchCategories = [
  'Fabric',
  'Wallpaper',
  'Bedding',
  'Dining',
  'Living&Decor',
  'Collections',
] as const

export function Search({
  locale,
  mobile,
}: {
  locale: string
  mobile?: boolean
}) {
  const [searchInput, setSearchInput] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowPreview(false)
      } else {
        if (searchInput.length > 2) {
          setShowPreview(true)
        }
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (searchInput.length > 3) {
      setShowPreview(true)
    }
  }, [searchInput])

  const handleSearch = (category: string = 'fabric') => {
    const currentUrl = new URL(window.location.href)
    const params = new URLSearchParams(currentUrl.search)
    const existingCategory = params.get('on')

    params.set('on', existingCategory ? existingCategory : category)
    params.set('q', searchInput)
    params.set('sort', 'bestSelling')
    params.set('autoApply', 'true')

    const newUrl = `${locale}/shop?${params.toString()}`
    window.location.assign(newUrl)
  }

  const handleEvent = (category = 'fabric') => {
    try {
      window?.dataLayer?.push({
        event: 'searchSubmit',
        q: searchInput,
        on: category,
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={`${styles.wrapper} ${mobile ? styles.mobile : ''}`}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleEvent()
          handleSearch()
        }}
      >
        <input
          ref={inputRef}
          type='text'
          id='search'
          role='combobox'
          aria-controls='search-suggestions'
          aria-autocomplete='list'
          aria-expanded={showPreview}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder={'Search'}
          className={`${styles.input} ${showPreview ? styles.showPreview : ''}`}
        />
        <button type='submit' className={styles.button} aria-label='search'>
          <SearchIcon />
        </button>
      </form>
      <menu
        id='search-suggestions'
        aria-label='search-suggestions'
        className={`${styles.preview} ${showPreview ? styles.showPreview : ''}`}
      >
        {searchCategories.map((key) => (
          <li
            id={key}
            key={key}
            value={`${searchInput}-${key}`}
            className={styles.previewItem}
          >
            <a
              href={`${locale}/shop?&on=${encodeURIComponent(
                key.toLowerCase()
              )}&q=${searchInput}&sort=bestSelling`}
              id={`search-${key}`}
              className={styles.previewLink}
              onClick={() => {
                handleEvent(key.toLowerCase())
              }}
            >
              <strong>{searchInput}</strong>
              {` in ${key.replace(/&/g, ' & ')}`}
            </a>
          </li>
        ))}
      </menu>
    </div>
  )
}
