import React from 'react'
import { IconProps } from '.'

export function AddIcon({
  width = 22,
  height = 22,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      focusable='false'
      width={width}
      height={height}
      fill='none'
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M11.0004 17.5999C10.7961 17.5999 10.6272 17.5331 10.4936 17.3995C10.36 17.266 10.2932 17.097 10.2932 16.8928V11.707H5.10753C4.90325 11.707 4.73432 11.6403 4.60075 11.5067C4.46718 11.3731 4.40039 11.2042 4.40039 10.9999C4.40039 10.7956 4.46718 10.6267 4.60075 10.4931C4.73432 10.3595 4.90325 10.2928 5.10753 10.2928H10.2932V5.10705C10.2932 4.90276 10.36 4.73383 10.4936 4.60026C10.6272 4.46669 10.7961 4.3999 11.0004 4.3999C11.2047 4.3999 11.3736 4.46669 11.5072 4.60026C11.6407 4.73383 11.7075 4.90276 11.7075 5.10705V10.2928H16.8932C17.0975 10.2928 17.2665 10.3595 17.4 10.4931C17.5336 10.6267 17.6004 10.7956 17.6004 10.9999C17.6004 11.2042 17.5336 11.3731 17.4 11.5067C17.2665 11.6403 17.0975 11.707 16.8932 11.707H11.7075V16.8928C11.7075 17.097 11.6407 17.266 11.5072 17.3995C11.3736 17.5331 11.2047 17.5999 11.0004 17.5999Z'
        fill={color}
      />
    </svg>
  )
}
