import { MenuLink } from '../Menu'

export const project: MenuLink[] = [
  {
    key: 'Activewear & Swimwear',
    url: '/en/inspiration/projects/fabric/activewear',
  },
  {
    key: 'Apparel',
    url: '/en/inspiration/projects/fabric/apparel',
  },
  {
    key: 'Bottom Weight',
    url: '/en/inspiration/projects/fabric/bottom-weight',
  },
  {
    key: 'Costume',
    url: '/en/inspiration/projects/fabric/costumes',
  },
  {
    key: 'Craft',
    url: '/en/inspiration/projects/fabric/craft',
  },
  {
    key: 'Dresses',
    url: '/en/inspiration/projects/fabric/dresses',
  },
  {
    key: 'Quilting',
    url: '/en/inspiration/projects/fabric/quilting',
  },
  {
    key: 'Top Weight',
    url: '/en/inspiration/projects/fabric/top-weight',
  },
  {
    key: 'Upholstery',
    url: '/en/inspiration/projects/fabric/upholstery',
  },
  {
    key: 'Wide Format',
    url: '/en/inspiration/projects/fabric/extra-wide-format',
  },
]
