import { MenuLink } from '../Menu'

export const dining: MenuLink[] = [
  { key: 'All Dining', url: '/en/home-decor/dining' },
  { key: 'Cocktail Napkins', url: '/en/home-decor/dining/cocktail-napkins' },
  { key: 'Dinner Napkins', url: '/en/home-decor/dining/dinner-napkins' },
  { key: 'Placemats', url: '/en/home-decor/dining/placemats' },
  { key: 'Tea Towels', url: '/en/home-decor/dining/tea-towel' },
  {
    key: 'Rectangular Tablecloths',
    url: '/en/home-decor/dining/rectangular-tablecloth',
  },
  { key: 'Round Tablecloths', url: '/en/home-decor/dining/round-tablecloth' },
  { key: 'Table Runners', url: '/en/home-decor/dining/table-runner' },
]
