/* eslint-disable max-lines*/
/* global dataLayer */
import classNames from 'classnames';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {setAlternateLocales} from '../actions/multiLocaleRouter';
import {pageUpdate} from '../actions/pageSetup';
import HeaderSecondary from '../components/Chrome/HeaderSecondary/HeaderSecondary';
import MobileNavigation from '../components/Chrome/MobileNavigation/MobileNavigation';
import Subscribe from '../components/Chrome/Subscribe/Subscribe';
import DoNotSellOrShareLazy from '../components/DoNotSell/DoNotSellLazy';
import ErrorLazy from '../components/Error/ErrorLazy';
import FlashMessage from '../components/Reusable/Content/FlashMessage/FlashMessage';
import {ErrorBoundary} from '../components/Reusable/ErrorBoundary/ErrorBoundary';
import Icon from '../components/Reusable/Icon/Icon';
import {HOMEPAGE, HOMEPAGE_PATHS} from '../constants/CmsPages';
import {countriesByCodeAndName} from '../constants/Countries';
import * as fm from '../constants/FlashMessages';
import {slideClasses} from '../constants/SlideClasses';
import {EN, languagesWithoutCategoriesColors} from '../constants/SupportedLanguages';
import {DO_NOT_SELL_OR_SHARE, USER_PREFERENCES} from '../constants/UserPreferences';
import {
  VIEWPORT_DESKTOP,
  VIEWPORT_SMALL_DESKTOP,
  getScreenHeight,
  getScreenWidth,
  getViewportFromScreenSize,
  getWindowPosition,
} from '../constants/Viewports';
import {GUEST, REGISTERED, UNAUTHENTICATED, unAuthOrGuestUser} from '../constants/userTypes';
import ModalContainer from '../containers/Modal/ModalContainer';
import selectCountries from '../entities/pageSetup/countries/selectors/selectCountries';
import {selectPageLang} from '../entities/pageSetup/selectors';
import {selectIsProduction} from '../environment/runtimeEnv/selectors';
import {MultiLocaleRouter, TranslateWrapper, translate} from '../services';
import {TEST_AB_TEST_SEARCH} from '../utils/abTestSearchVariant';
import {getCookieNameByPrefix} from '../utils/cookies';
import {abTestEvent} from '../utils/dataLayer';
import {openModalDispatcher} from '../utils/dispatchToPropsHelpers';
import {slideInModalOnClick} from '../utils/slideInModalOnClick';
import {orderItemFromParams} from '../utils/stateToPropsHelpers';
import {debounce} from '../utils/timers';
import {isEmpty, isNotUndefined, isUndefined, objectIsEmpty} from '../utils/validation';
import CmsContainer from './Cms/CmsContainer';
import fetchAnnouncements from './GlobalStructure/api/fetchAnnouncements';
import UserPreferencesFormContainerLazy from './UserPreferences/UserPreferencesFormContainerLazy';
import {Footer, Header} from '@spoonflower/ui/dist/index.js';

class GlobalStructureContainer extends Component {
  constructor(props) {
    super(props);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.updateViewport = this.updateViewport.bind(this);
    this.updateWindowHeight = this.updateWindowHeight.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.updateWindowPosition = this.updateWindowPosition.bind(this);
    this.handleMobalNavClose = this.handleMobalNavClose.bind(this);
    this.handleMobalNavOpen = this.handleMobalNavOpen.bind(this);
    this.handleDispatchOpenModal = this.handleDispatchOpenModal.bind(this);
    this.backToTopRef = React.createRef();
    // TODO (SP-6156): Remove
    this.componentNameForFlashMessage = 'GlobalStructureContainer';
    this.state = {
      hideMobileMenu: !props.showSlideInComponent,
      slideInModal: false,
      promoBarOpen: false,
    };
  }

  componentDidMount() {
    const {loadAnnouncements, announcements} = this.props;
    const that = this;

    this.updateViewport();
    this.updateWindowWidth();
    this.updateWindowHeight();
    this.updateWindowPosition();
    if (isEmpty(announcements)) {
      loadAnnouncements();
    }
    this.setAlternateLocalesForLocation();

    window.addEventListener('resize', debounce(this.updateViewport, 250));
    window.addEventListener('resize', debounce(this.updateWindowWidth, 250));
    window.addEventListener('resize', debounce(this.updateWindowHeight, 250));
    window.addEventListener('scroll', debounce(this.updateWindowPosition, 250));
    window.addEventListener(
      'touchstart',
      function onFirstTouch() {
        that.props.updatePageTouch();
        window.removeEventListener('touchstart', onFirstTouch, false);
      },
      false
    );

    const searchTestCookieName = getCookieNameByPrefix(Cookies.get(), TEST_AB_TEST_SEARCH);
    const searchTestCookieValue = Cookies.get(searchTestCookieName);

    if (searchTestCookieName) {
      dataLayer.push(abTestEvent(searchTestCookieName, searchTestCookieValue));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      params,
      locationLang,
      locationPathNameWithQuery,
      displayModal,
      hideSlideInComponent,
      showSlideInComponent,
      viewport,
      slideInComponent,
      updatePageName,
    } = this.props;

    if (locationLang !== prevProps.locationLang) {
      TranslateWrapper.setLangCode(locationLang);
    }

    if (locationPathNameWithQuery !== prevProps.locationPathNameWithQuery) {
      this.setAlternateLocalesForLocation(); // todo understand wht it is here
      updatePageName(params.pageName);
    }

    if (displayModal !== prevProps.displayModal) {
      slideInModalOnClick(this);
    }

    if (
      (viewport !== prevProps.viewport && viewport === VIEWPORT_DESKTOP) ||
      (!isEmpty(slideInComponent) &&
        (slideInComponent.name === 'SubstrateFilter' || slideInComponent.name === 'SearchFiltersContainer') &&
        showSlideInComponent &&
        viewport !== prevProps.viewport &&
        viewport === VIEWPORT_SMALL_DESKTOP)
    ) {
      hideSlideInComponent();
      this.toggleBodyClass(false);
      this.setState({
        hideMobileMenu: true,
      });
    }

    // handle toggling from substrate filter and search filter
    if (
      showSlideInComponent !== prevProps.showSlideInComponent &&
      isNotUndefined(viewport) &&
      viewport !== VIEWPORT_DESKTOP
    ) {
      this.toggleBodyClass(showSlideInComponent);
      this.setState({
        hideMobileMenu: !showSlideInComponent,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewport);
    window.removeEventListener('resize', this.updateWindowWidth);
    window.removeEventListener('resize', this.updateWindowHeight);
    window.removeEventListener('scroll', this.updateWindowPosition);

    this.timeoutId && clearTimeout(this.timeoutId);
  }
  setAlternateLocalesForLocation() {
    const {locationPathNameWithQuery, setLocales} = this.props;
    const alternateLocales = MultiLocaleRouter.alternateLocales(locationPathNameWithQuery);

    setLocales(alternateLocales);
  }

  updateViewport() {
    this.props.updateViewport();
  }

  updateWindowWidth() {
    this.props.updateWindowWidth();
  }

  updateWindowHeight() {
    this.props.updateWindowHeight();
  }

  updateWindowPosition() {
    this.props.updateWindowPosition();
  }

  togglePromoBarOnClick = () => {
    this.setState({
      promoBarOpen: !this.state.promoBarOpen,
    });
  }

  showSubscribeBanner = () => unAuthOrGuestUser(this.props.userType) && <Subscribe locale={this.props.locale} />

  checkPageName(pageName) {
    if (isUndefined(pageName) && HOMEPAGE_PATHS.includes(this.props.pathName)) {
      return HOMEPAGE;
    }

    return pageName;
  }

  toggleMobileMenu(slideInComponent) {
    const {showSlideInComponent, dispatchPageUpdateSlideIn} = this.props;

    dispatchPageUpdateSlideIn(slideInComponent, !showSlideInComponent);
    this.toggleBodyClass(showSlideInComponent);

    this.setState({
      hideMobileMenu: !this.state.hideMobileMenu,
    });
  }

  toggleBodyClass(showSlideInComponent) {
    if (showSlideInComponent === true) {
      window.scrollTo(0, 0);
      document.body.classList.add('display-modal');
    } else {
      document.body.classList.remove('display-modal');
    }
  }

  handleMobalNavClose(element) {
    this.mobileNavCloseButton = element;
  }

  handleMobalNavOpen(element) {
    this.mobileNavOpenButton = element;
  }

  handleDispatchOpenModal() {
    const {dispatchOpenModal} = this.props;

    dispatchOpenModal('user-preferences');
  }

  renderSlideComponent = () => {
    const {
      userId,
      userName,
      userDesignOwner,
      userIsPro,
      userBusinessTools,
      viewport,
      userType,
      slideInComponent,
      showSlideInComponent,
      locale,
    } = this.props;
    let SlideComponent = MobileNavigation;
    let slideInComponentProps = {
      userType,
      userName,
      userId,
      userDesignOwner,
      userIsPro,
      userBusinessTools,
      locale,
      viewport,
    };
    const slideInComponentWrapperClasses = classNames('b-mobile-navigation-cover', {
      'x-hidden': this.state.hideMobileMenu,
      'b-filter': !isEmpty(slideInComponent) && slideInComponent.name === 'SearchFiltersContainer',
      'x-substrate-filter': !isEmpty(slideInComponent) && slideInComponent.name === 'SubstrateFilter',
    });

    if (!isEmpty(slideInComponent) && slideInComponent.name && showSlideInComponent) {
      SlideComponent = slideClasses[slideInComponent.name]; // Needs to be uppercase for component Typecasting
      slideInComponentProps = Object.assign({}, slideInComponentProps, slideInComponent.props);
    }

    return (
      <section
        className={slideInComponentWrapperClasses}
        onClick={this.toggleMobileMenu}
        aria-modal='true'
        role='dialog'
      >
        <button
          type='button'
          ref={this.handleMobalNavClose}
          title={translate('common.forms.close')}
          aria-label={translate('common.forms.close')}
          className='btn btn-close'
          onClick={this.toggleMobileMenu}
        >
          <Icon iconName='close' iconTitle={translate('common.forms.close')} />
        </button>
        <SlideComponent {...slideInComponentProps} />
      </section>
    );
  }

  renderContent = () => {
    const {params, pageLang, children, shouldRenderErrorPage} = this.props;

    if (shouldRenderErrorPage.shouldRender404) {
      return <ErrorLazy errorType={404} />;
    }
    const pageName = this.checkPageName(params && params.pageName);
    const childrenProps = children;

    return isUndefined(childrenProps) || isEmpty(childrenProps) || pageName ? (
      <CmsContainer pageLang={pageLang} pageName={pageName} />
    ) : (
      childrenProps
    );
  }

  render() {
    const {
      user,
      userId,
      userName,
      userDesignOwner,
      userType,
      pendingOrderItems,
      viewport,
      flashMessage,
      headerTitle,
      pageTitle,
      headerExtensionClass,
      contentInnerExtensions,
      headerSecondary,
      displayFooter,
      displayHeader,
      displayNavigation,
      locationLang,
      touchDetected,
      countryKey,
      country,
      locale,
      countries,
      location,
      displayModal,
      modalName,
      announcements,
      isProduction,
      cartItemsCount,
      isProductionEnv,
    } = this.props;
    const contentInnerClasses = classNames('content-inner', contentInnerExtensions);
    const basicEnvironmentClass = classNames('basic-wrapper', {
      'x-development': !isProduction,
    });
    const content = this.renderContent();
    const regionalAnnouncements = announcements.filter((item) => item.language === locale);
    const primaryAnnouncement = regionalAnnouncements[0] && regionalAnnouncements[0].title;
    const hideCategoriesColors = languagesWithoutCategoriesColors.includes(locationLang);
    const localeIsEnglish = locale === EN;
    const contentForModal =
      modalName === USER_PREFERENCES ? (
        <UserPreferencesFormContainerLazy countries={countries} />
      ) : modalName === DO_NOT_SELL_OR_SHARE ? (
        <DoNotSellOrShareLazy userType={userType} userId={userId} />
      ) : null;
    const recentlyViewedUrl = isProductionEnv ?
      'https://www.spoonflower.com/en/recently-viewed' :
      'https://staging.spoonflower.com/en/recently-viewed';

    return (
      <React.Fragment>
        <Helmet title={pageTitle} />
        <div className={basicEnvironmentClass} ref={this.backToTopRef}>
          {displayHeader && (
            <Header
              user={user}
              announcements={announcements}
              cartCount={cartItemsCount}
              cartUrl={isProductionEnv ? 'https://cart.spoonflower.com' : 'https://test.spoonflower.com'}
              recentlyViewedUrl={recentlyViewedUrl}
            />
          )}
          <main className='main'>
            {headerSecondary?.displayHeaderSecondary && (
              <HeaderSecondary
                message={headerTitle}
                viewport={viewport}
                isPendingOrderItemsUndefined={isUndefined(pendingOrderItems)}
                headerType={headerSecondary.headerType}
              />
            )}
            {flashMessage &&
              // TODO (SP-6156): Remove the next two lines
              (!flashMessage.targetComponent || flashMessage.targetComponent === this.componentNameForFlashMessage) && (
              <FlashMessage message={flashMessage.message} severity={flashMessage.severity} />
            )}
            <ErrorBoundary>
              <section className={contentInnerClasses}>{content}</section>
            </ErrorBoundary>
          </main>
          {displayModal && contentForModal && (
            <ModalContainer
              contents={contentForModal}
              slideInModal={this.state.slideInModal}
              modalWithButton
              modalWithReset
            />
          )}
          {this.showSubscribeBanner()}
          {displayFooter && <Footer user={user} />}
          {viewport !== VIEWPORT_DESKTOP && this.renderSlideComponent()}
        </div>
      </React.Fragment>
    );
  }
}

GlobalStructureContainer.propTypes = {
  user: PropTypes.object,
  userIsPro: PropTypes.bool,
  userBusinessTools: PropTypes.bool,
  userType: PropTypes.oneOf([UNAUTHENTICATED, GUEST, REGISTERED]),
  children: PropTypes.node,
  pathName: PropTypes.string.isRequired,
  locationPathNameWithQuery: PropTypes.string.isRequired,
  locationLang: PropTypes.string.isRequired,
  flashMessage: PropTypes.shape({
    severity: PropTypes.oneOf(fm.MESSAGE_SEVERITIES).isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    targetComponent: PropTypes.string,
  }),
  headerTitle: PropTypes.string,
  pageTitle: PropTypes.string,
  headerExtensionClass: PropTypes.string,
  contentInnerExtensions: PropTypes.string,
  displayFooter: PropTypes.bool,
  displayHeader: PropTypes.bool,
  displayNavigation: PropTypes.bool,
  userId: PropTypes.number,
  userName: PropTypes.string,
  userDesignOwner: PropTypes.bool,
  pendingOrderItems: PropTypes.array,
  cartItemsCount: PropTypes.number,
  viewport: PropTypes.string,
  slideInComponent: PropTypes.shape({
    name: PropTypes.string,
    props: PropTypes.object,
  }),
  showSlideInComponent: PropTypes.bool,
  hideSlideInComponent: PropTypes.func,
  dispatch: PropTypes.func,
  setLocales: PropTypes.func.isRequired,
  updateViewport: PropTypes.func.isRequired,
  updatePageTouch: PropTypes.func.isRequired,
  dispatchPageUpdateSlideIn: PropTypes.func.isRequired,
  shippingCostFlashMessage: PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.oneOf(fm.MESSAGE_SEVERITIES),
  }),
  announcements: PropTypes.array,
  locale: PropTypes.string,
  loadAnnouncements: PropTypes.func,
  location: PropTypes.object,
  touchDetected: PropTypes.bool,
  countryKey: PropTypes.string,
  pageLang: PropTypes.string,
  country: PropTypes.string,
  dispatchOpenModal: PropTypes.func,
  displayModal: PropTypes.bool,
  modalName: PropTypes.string,
  updateWindowWidth: PropTypes.func,
  updateWindowHeight: PropTypes.func,
  updateWindowPosition: PropTypes.func,
  updatePageName: PropTypes.func,
  params: PropTypes.shape({
    pageName: PropTypes.string,
  }),
  headerSecondary: PropTypes.shape({
    displayHeaderSecondary: PropTypes.bool,
    headerType: PropTypes.string,
  }),
  isProduction: PropTypes.bool.isRequired,
  shouldRenderErrorPage: PropTypes.shape({
    shouldRender404: PropTypes.bool,
  }),
  countries: PropTypes.object,
  isCartDataFetching: PropTypes.bool,
  isProductionEnv: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const locationPathNameWithQuery = ownProps.location.pathname + ownProps.location.search;
  const locationLang = ownProps.location.pathname.split('/')[1];
  const pathName = ownProps.location.pathname;
  const pageLang = selectPageLang(state);
  const locale = get(ownProps.params, 'pageLang', pageLang);
  const orderItem = orderItemFromParams(state) || {};

  const {
    pageSetup: {
      headerTitle,
      pageTitle,
      headerExtensionClass,
      contentInnerExtensions,
      displayFooter,
      displayHeader,
      headerSecondary,
      displayNavigation,
      slideInComponent,
      showSlideInComponent,
      modal,
      viewport,
      touchDetected,
    },
    pageSetup,
    user,
    carts: {count: {count: cartItemsCount, isCartItemsCountFetching},},
  } = state;

  const latestOrderItem = !objectIsEmpty(orderItem) ? orderItem : state.orderItemFromResponse;
  const orderItemIsFetching = !objectIsEmpty(latestOrderItem) && !!latestOrderItem.isFetching;

  const countryKey = user.preferences.country;

  const props = {
    locationPathNameWithQuery,
    locationLang,
    countryKey,
    pathName,
    pageLang,
    headerTitle,
    pageTitle,
    headerExtensionClass,
    contentInnerExtensions,
    displayFooter,
    displayHeader,
    headerSecondary,
    displayNavigation,
    flashMessage: state.flashMessage.message && state.flashMessage,
    userName: user && user.screen_name,
    userDesignOwner: user && user.design_owner,
    slideInComponent,
    showSlideInComponent,
    announcements: state.announcements,
    locale,
    touchDetected,
    viewport,
    country: countriesByCodeAndName(selectCountries(state), locale)[countryKey],
    isProduction: selectIsProduction(state),
    countries: selectCountries(state),
    shouldRenderErrorPage: state.shouldRenderErrorPage,
    cartItemsCount,
    isCartDataFetching: !!(state.addToCart.isFetching | orderItemIsFetching | isCartItemsCountFetching),
    featureFlags: state.featureFlags,
    user,
    isProductionEnv: selectIsProduction(state),
  };

  if (!isUndefined(modal)) {
    Object.assign(props, {
      displayModal: pageSetup.modal.displayModal,
      modalName: pageSetup.modal.modalName,
    });
  }

  if (!objectIsEmpty(user)) {
    Object.assign(props, {
      userId: user.id,
      userIsPro: user.pro,
      userBusinessTools: user.business_tools,
      userType: user.userType,
    });
  }

  if (state.carts.pending.pendingCartFetched || !objectIsEmpty(state.carts.pending.pricing)) {
    Object.assign(props, {
      pendingOrderItems: state.carts.pending.order_items,
    });
  }

  return props;
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadAnnouncements: () => dispatch(fetchAnnouncements()),
  setLocales: (alternateLocales) => dispatch(setAlternateLocales(alternateLocales)),
  updatePageTouch: () =>
    dispatch(
      pageUpdate({
        touchDetected: true,
      })
    ),
  updatePageName: (pageName) =>
    dispatch(
      pageUpdate({
        pageName,
      })
    ),
  updateViewport: () =>
    dispatch(
      pageUpdate({
        viewport: getViewportFromScreenSize(),
      })
    ),
  updateWindowWidth: () =>
    dispatch(
      pageUpdate({
        windowWidth: getScreenWidth(),
      })
    ),
  updateWindowHeight: () =>
    dispatch(
      pageUpdate({
        windowHeight: getScreenHeight(),
      })
    ),
  updateWindowPosition: () =>
    dispatch(
      pageUpdate({
        windowPosition: getWindowPosition(),
      })
    ),
  hideSlideInComponent: () =>
    dispatch(
      pageUpdate({
        showSlideInComponent: false,
      })
    ),
  dispatchPageUpdateSlideIn: (slideInComponent, showSlideInComponent) =>
    dispatch(
      pageUpdate({
        slideInComponent,
        showSlideInComponent,
      })
    ),
  dispatchOpenModal: openModalDispatcher(dispatch),
});

const GlobalStructureContainerConnected = connect(mapStateToProps, mapDispatchToProps)(GlobalStructureContainer);

GlobalStructureContainerConnected.fetchDataOnServer = new Set([fetchAnnouncements]);
GlobalStructureContainerConnected.fetchDataOnServerIfSingleRoute = new Set([...CmsContainer.fetchDataOnServer]);
GlobalStructureContainerConnected.extendInitialStateOnServerIfSingleRoute = new Set([
  ...CmsContainer.extendInitialStateBeforeDataLoad,
]);

export default GlobalStructureContainerConnected;
