import React from 'react'
import { IconProps } from '.'

export function ArrowRightIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 28 13'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M26.039 7.11537H0.613781C0.431675 7.11537 0.283851 7.05676 0.170311 6.93954C0.0567702 6.82232 0 6.66972 0 6.48174C0 6.29376 0.0567702 6.14114 0.170311 6.02389C0.283851 5.90667 0.431675 5.84806 0.613781 5.84806H26.0789L21.9115 1.54555C21.801 1.43149 21.7457 1.2942 21.7457 1.13367C21.7457 0.973124 21.804 0.832659 21.9206 0.712281C22.0577 0.57076 22.204 0.5 22.3595 0.5C22.515 0.5 22.6613 0.57076 22.7984 0.712281L27.6409 5.68014C27.7535 5.79632 27.8363 5.92305 27.8895 6.06034C27.9427 6.19764 27.9795 6.3381 28 6.48174C28 6.64649 27.9683 6.79222 27.9049 6.91894C27.8415 7.04569 27.7535 7.16714 27.6409 7.28329L22.7984 12.3145C22.6551 12.4413 22.5124 12.5031 22.3702 12.4999C22.2281 12.4967 22.0884 12.4244 21.9514 12.2829C21.8143 12.1413 21.7457 11.9887 21.7457 11.8251C21.7457 11.6614 21.8112 11.5119 21.9421 11.3767L26.039 7.11537Z'
      ></path>
    </svg>
  )
}
