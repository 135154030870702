/* eslint-disable max-lines */

import {TreeBranch} from '../../components/Shop/SearchTree/SearchTree';
import {keyColorModifier} from '../../constants/Navigation';
import {
  URL_QUERY_PARAM_CATEGORY_BEDDING_API_VALUE,
  URL_QUERY_PARAM_CATEGORY_DINING_API_VALUE,
  URL_QUERY_PARAM_CATEGORY_LIVING_API_VALUE
} from '../../constants/Parameters';
import {translate} from '../../services';


interface MenuItem {
  id: string;
  name: string;
  href: string;
  apiValue: string;
}

export const translatingMenuItemArray = (arrayOfTextKeys: string[], parentId: string): MenuItem[] => arrayOfTextKeys.reduce((acc, current) => {
  if (current) {
    const colorKey = keyColorModifier(current);

    acc.push({
      id: `${parentId}.${current}`,
      name: translate(`navigation.${colorKey}.text`),
      href: translate(`navigation.${current}.href`),
      apiValue: translate(`navigation.${current}.apiValue`).includes('navigation') ? colorKey : translate(`navigation.${current}.apiValue`),
    });
  }

  return acc;
}, [] as MenuItem[]);

export const navigationBySort = (): TreeBranch => ({
  id: 'sortMenu',
  name: translate('navigation.sortMenu.text'),
  children: translatingMenuItemArray(['bestSelling', 'mostRelevant', 'mostFavorited', 'trending', 'newest'], 'sortMenu')
});

export const navigationByStyle = (): TreeBranch => ({
  id: 'styleMenu',
  name: translate('navigation.style.text'),
  dataCategory: 'parentCategory',
  children: [
    {
      id: 'styleMenu.modernMenu',
      name: translate('navigation.modern.text'),
      children: translatingMenuItemArray(
        ['allModern', 'abstract', 'arrows', 'artDeco', 'chevron', 'geometric', 'hipster', 'midCenturyModern', 'ikat', 'mod', 'ombre'],
        'styleMenu.modernMenu')
    },
    {
      id: 'styleMenu.traditionalMenu',
      name: translate('navigation.traditional.text'),
      children: translatingMenuItemArray(
        ['allTraditional', 'check', 'chinoiserie', 'damask', 'dots', 'gingham', 'herringbone', 'houndstooth', 'paisley', 'plaid',
          'preppy', 'shabbyChic', 'stripes', 'toile', 'vintage'],
        'styleMenu.traditionalMenu')
    },
    {
      id: 'styleMenu.historicalMenu',
      name: translate('navigation.historical.text'),
      children: translatingMenuItemArray(['allHistorical', 'artDeco', 'artNouveau', 'gothic', 'medieval', 'midCenturyModern', 'popArt', 'renaissance', 'rococo', 'retro',
        'steampunk', 'victorian', 'vintage', '60s', '70s', '80s', '90s'], 'styleMenu.historicalMenu')
    },
    {
      id: 'styleMenu.nauticalMenu',
      name: translate('navigation.nautical.text'),
      children: translatingMenuItemArray(['allNautical', 'anchors', 'boats', 'buoys', 'knots', 'lighthouse', 'lobster', 'map', 'mermaid', 'octopus', 'shells', 'ship'], 'styleMenu.nauticalMenu')
    },
    {
      id: 'styleMenu.artisticMenu',
      name: translate('navigation.artistic.text'),
      children: translatingMenuItemArray(['allArtistic', 'abstract', 'brushStroke', 'handDrawn', 'photography', 'sketches', 'splatterPaint', 'watercolor'], 'styleMenu.artisticMenu')
    },
    {
      id: 'styleMenu.shapesMenu',
      name: translate('navigation.shapes.text'),
      children: translatingMenuItemArray(['allShapes', 'arrows', 'circle', 'cross', 'dots', 'fleurDeLis', 'geometric', 'grid', 'diamond', 'hearts', 'hexagon',
        'honeycombPattern', 'pixel', 'quatrefoil', 'stars', 'trellis', 'triangles'], 'styleMenu.shapesMenu')
    },
    {
      id: 'styleMenu.textureMenu',
      name: translate('navigation.texture.text'),
      children: translatingMenuItemArray(['allTexture', 'birchBark', 'lace', 'linen', 'marble', 'shibori', 'stainedGlass', 'wood', 'woodgrain'], 'styleMenu.textureMenu')
    },
    {
      id: 'styleMenu.geographyMenu',
      name: translate('navigation.geography.text'),
      children: translatingMenuItemArray(['allGeography', 'asian', 'chinese', 'scandinavian', 'mexican', 'paris', 'chinoiserie', 'indian', 'kawaii', 'african',
        'london', 'hawaiian', 'french', 'tropical', 'japanese', 'turkish'], 'styleMenu.geographyMenu')
    },
    {
      id: 'styleMenu.worldlyMenu',
      name: translate('navigation.worldly.text'),
      children: translatingMenuItemArray(['allWorldly', 'aztec', 'bohemian', 'celtic', 'ikat', 'mandala', 'mapleLeaf',
        'moroccan', 'mudcloth', 'nativeAmerican', 'persian', 'tribal'], 'styleMenu.worldlyMenu')
    }
  ]
});

export const navigationByProduct = (additionalSubstrates: string[] = []): TreeBranch => ({
  id: 'productMenu',
  name: translate('navigation.product.text'),
  dataCategory: 'parentCategory',
  children: [
    {
      id: 'productMenu.allProducts', name: translate('navigation.allProducts.text'), apiValue: 'allProducts'
    },
    {
      id: 'productMenu.fabric', name: translate('navigation.fabric.text'), apiValue: 'fabric'
    },
    {
      id: 'productMenu.wallpaper', name: translate('navigation.wallpaper.text'), apiValue: 'wallpaper',
      children: translatingMenuItemArray(
        ['wallpaperPeelAndStick', 'wallpaperSmooth', 'wallpaperGrasscloth', 'wallpaperIsobar', 'wallpaperSilverMetallic', 'wallpaperGoldMetallic', ...additionalSubstrates],
        'productMenu.wallpaper'
      )
    },
    {
      id: 'productMenu.diningMenu', name: translate('navigation.dining.text'),
      apiValue: URL_QUERY_PARAM_CATEGORY_DINING_API_VALUE,
      children: translatingMenuItemArray(
        ['allDining', 'cocktailNapkins', 'dinnerNapkins', 'placemats', 'teaTowels', 'rectangularTablecloths', 'roundTablecloths', 'tableRunners'],
        'productMenu.diningMenu'
      )
    },
    {
      id: 'productMenu.livingMenu',
      name: translate('navigation.livingDecor.text'),
      apiValue: URL_QUERY_PARAM_CATEGORY_LIVING_API_VALUE,
      children: translatingMenuItemArray(
        ['allLiving', 'squareThrowPillows', 'lumbarThrowPillows', 'throwBlankets', 'curtains'],
        'productMenu.livingMenu'
      )
    },
    {
      id: 'productMenu.beddingMenu',
      name: translate('navigation.bedding.text'),
      apiValue: URL_QUERY_PARAM_CATEGORY_BEDDING_API_VALUE,
      children: translatingMenuItemArray(['allBedding', 'duvetCovers', 'sheetSets', 'standardPillowShams', 'euroPillowShams', 'longLumbarThrowPillow'], 'productMenu.beddingMenu')
    }
  ]
});

export const navigationByWinnersOnly = (): TreeBranch => ({
  id: 'winnersOnlyMenu',
  name: translate('navigation.winnersOnlyMenu.text'),
  dataCategory: 'parentCategory',
  children: [
    {
      id: 'winnersOnlyMenu.winnersOnly', name: translate('navigation.showWinnersOnly.text'), apiValue: 'winnersOnly'
    },
  ]
});

export const navigationByColors = (): TreeBranch => ({
  id: 'colorMenu',
  name: translate('navigation.colorMenu.text'),
  dataCategory: 'parentCategory',
  children: translatingMenuItemArray([
    'black', 'brown', 'grey', 'white', 'yellow', 'green', 'blue', 'purple', 'pink', 'red', 'orange'
  ], 'colorMenu')
});

export const navigationByTopic = (): TreeBranch => ({
  id: 'topicMenu',
  name: translate('navigation.topic.text'),
  dataCategory: 'parentCategory',
  children: [
    {
      id: 'topicMenu.animalsMenu',
      name: translate('navigation.animals.text'),
      children: [
        {
          id: 'topicMenu.animalsMenu.allAnimals',
          name: translate('navigation.allAnimals.text'),
          href: translate('navigation.allAnimals.href')
        },
        {
          id: 'topicMenu.animalsMenu.woodlandMenu',
          name: translate('navigation.woodlandAnimals.text'),
          children: translatingMenuItemArray(
            ['allWoodlandAnimals', 'bat', 'bear', 'deer', 'fox', 'hedgehog', 'moose', 'owl', 'rabbit', 'squirrel', 'stag', 'wolf'],
            'topicMenu.animalsMenu.woodlandMenu'
          )
        },
        {
          id: 'topicMenu.animalsMenu.farmMenu',
          name: translate('navigation.farm.text'),
          children: translatingMenuItemArray(['allFarm', 'chicken', 'cows', 'donkey', 'ducks', 'equestrian', 'goat', 'hen', 'horse', 'llama', 'pig', 'sheep'], 'topicMenu.animalsMenu.farmMenu')
        },
        {
          id: 'topicMenu.animalsMenu.petsMenu',
          name: translate('navigation.pets.text'),
          children: translatingMenuItemArray(['allPets', 'bird', 'cat', 'dog', 'fish', 'hamster', 'parrot', 'snake', 'spider'], 'topicMenu.animalsMenu.petsMenu')
        },
        {
          id: 'topicMenu.animalsMenu.oceanMenu',
          name: translate('navigation.ocean.text'),
          children: translatingMenuItemArray(['allOcean', 'crab', 'dolphin', 'fish', 'jellyfish', 'koi', 'lobster',
            'octopus', 'penguin', 'seahorse', 'shark', 'starfish', 'whale'], 'topicMenu.animalsMenu.oceanMenu')
        },
        {
          id: 'topicMenu.animalsMenu.insectsMenu',
          name: translate('navigation.insects.text'),
          children: translatingMenuItemArray(['allInsects', 'bee', 'beetle', 'bug', 'butterfly', 'caterpillar', 'dragonfly', 'firefly', 'spider'], 'topicMenu.animalsMenu.insectsMenu')
        },
        {
          id: 'topicMenu.animalsMenu.birdsMenu',
          name: translate('navigation.birds.text'),
          children: translatingMenuItemArray(['allBirds', 'flamingo', 'hummingbird', 'owl', 'parrot', 'peacock', 'robin', 'sparrow', 'toucan'], 'topicMenu.animalsMenu.birdsMenu')
        },
        {
          id: 'topicMenu.animalsMenu.zooMenu',
          name: translate('navigation.zoo.text'),
          children: translatingMenuItemArray(['allZoo', 'bears', 'giraffe', 'hippo', 'llama', 'lions', 'monkey', 'panda', 'rhino', 'tigers', 'zebra'], 'topicMenu.animalsMenu.zooMenu')
        },
        {
          id: 'topicMenu.animalsMenu.safariMenu',
          name: translate('navigation.safari.text'),
          children: translatingMenuItemArray(
            ['allSafari', 'cheetah', 'elephant', 'giraffe', 'hippo', 'leopard', 'lions', 'monkey', 'rhino', 'sloth', 'tigers', 'zebra'],
            'topicMenu.animalsMenu.safariMenu'
          )
        },
        {
          id: 'topicMenu.animalsMenu.dogBreedsMenu',
          name: translate('navigation.dogBreeds.text'),
          children: translatingMenuItemArray(
            ['allDogBreeds', 'bulldog', 'corgi', 'dachshund', 'dalmatian', 'goldenRetriever', 'greyhound', 'poodle', 'pug', 'yorkie'],
            'topicMenu.animalsMenu.dogBreedsMenu'
          )
        }
      ]
    },
    {
      id: 'topicMenu.natureMenu',
      name: translate('navigation.nature.text'),
      children: [
        {
          id: 'topicMenu.natureMenu.allNature',
          name: translate('navigation.allNature.text'),
          href: translate('navigation.allNature.href')
        },
        {
          id: 'topicMenu.natureMenu.botanicalMenu',
          name: translate('navigation.botanical.text'),
          children: translatingMenuItemArray(['allBotanical', 'cactus', 'dandelion', 'fauna', 'feathers', 'flora', 'flowers', 'fruit', 'leaves', 'mushroom', 'palm',
            'peony', 'poppy', 'rose', 'seeds', 'succulents', 'sunflower', 'thistles', 'trees'], 'topicMenu.natureMenu.botanicalMenu')
        },
        {
          id: 'topicMenu.natureMenu.spaceMenu',
          name: translate('navigation.space.text'),
          children: translatingMenuItemArray(
            ['allSpace', 'asteroids', 'astronaut', 'constellations', 'galaxy', 'moon', 'nebula', 'planets', 'sky', 'stars', 'sun', 'zodiac'],
            'topicMenu.natureMenu.spaceMenu'
          )
        },
        {
          id: 'topicMenu.natureMenu.weatherMenu',
          name: translate('navigation.weather.text'),
          children: translatingMenuItemArray(['allWeather', 'clouds', 'ice', 'lightning', 'rain', 'rainbow', 'snow', 'storm', 'sun', 'tropical', 'umbrella'], 'topicMenu.natureMenu.weatherMenu')
        },
        {
          id: 'topicMenu.natureMenu.birdsMenu',
          name: translate('navigation.birds.text'),
          children: translatingMenuItemArray(['allBirds', 'feathers', 'flamingo', 'hummingbird', 'nest', 'parrot', 'robin', 'sparrow', 'toucan'], 'topicMenu.natureMenu.birdsMenu')
        },
        {
          id: 'topicMenu.natureMenu.woodlandMenu',
          name: translate('navigation.woodlandNature.text'),
          children: translatingMenuItemArray(['allWoodlandNature', 'antler', 'bat', 'bear', 'birchBark', 'deer', 'fox', 'hedgehog', 'moose', 'mushroom', 'owl',
            'rabbit', 'squirrel', 'stag', 'trees', 'wolf'], 'topicMenu.natureMenu.woodlandMenu')
        },
        {
          id: 'topicMenu.natureMenu.seaMenu',
          name: translate('navigation.sea.text'),
          children: translatingMenuItemArray(['allSea', 'coral', 'island', 'ocean', 'sand', 'seaweed', 'starfish', 'surf', 'waves'], 'topicMenu.natureMenu.seaMenu')
        },
        {
          id: 'topicMenu.natureMenu.outdoorsMenu',
          name: translate('navigation.outdoors.text'),
          children: translatingMenuItemArray(['allOutdoors', 'birch', 'campfire', 'camping', 'desert', 'forest',
            'jungle', 'lake', 'mountain', 'smores', 'teepee', 'tents', 'trees'], 'topicMenu.natureMenu.outdoorsMenu')
        }
      ]
    },
    {
      id: 'topicMenu.noveltyMenu',
      name: translate('navigation.novelty.text'),
      children: [
        {
          id: 'topicMenu.noveltyMenu.allNovelty',
          name: translate('navigation.allNovelty.text'),
          href: translate('navigation.allNovelty.href')
        },
        {
          id: 'topicMenu.noveltyMenu.foodAndBeverageMenu',
          name: translate('navigation.foodAndBeverage.text'),
          children: translatingMenuItemArray(['allFood', 'banana', 'candy', 'coffee', 'cookies', 'donuts', 'fruit', 'iceCream', 'lemon', 'pineapple', 'pizza', 'popcorn',
            'popsicle', 'sprinkles', 'strawberry', 'sushi', 'watermelon'], 'topicMenu.noveltyMenu.foodAndBeverageMenu')
        },
        {
          id: 'topicMenu.noveltyMenu.musicMenu',
          name: translate('navigation.music.text'),
          children: translatingMenuItemArray(
            ['allMusic', 'drums', 'guitar', 'jazz', 'musicNotes', 'piano', 'radio', 'rockAndRoll', 'symphony', 'trumpets', 'violin'],
            'topicMenu.noveltyMenu.musicMenu')
        },
        {
          id: 'topicMenu.noveltyMenu.academiaMenu',
          name: translate('navigation.academia.text'),
          children: translatingMenuItemArray(['allAcademia', 'atomic', 'book', 'geek', 'math', 'school', 'science', 'teacher'], 'topicMenu.noveltyMenu.academiaMenu')
        },
        {
          id: 'topicMenu.noveltyMenu.sewingNotionsMenu',
          name: translate('navigation.sewingNotions.text'),
          children: translatingMenuItemArray(['allSewingNotions', 'knitting', 'sewingMachines', 'scissors', 'tapeMeasure', 'thimbles', 'thread'], 'topicMenu.noveltyMenu.sewingNotionsMenu')
        },
        {
          id: 'topicMenu.noveltyMenu.sportsMenu',
          name: translate('navigation.sports.text'),
          children: translatingMenuItemArray(['allSports', 'ballet', 'baseball', 'basketball', 'bicycle', 'equestrian',
            'fishing', 'football', 'golf', 'gymnastics', 'soccer', 'surfing', 'tennis'], 'topicMenu.noveltyMenu.sportsMenu')
        },
        {
          id: 'topicMenu.noveltyMenu.scaryMenu',
          name: translate('navigation.scary.text'),
          children: translatingMenuItemArray(['allScary', 'bat', 'ghost', 'gothic', 'monster', 'skull', 'steampunk', 'wolf', 'zombie'], 'topicMenu.noveltyMenu.scaryMenu')
        },
        {
          id: 'topicMenu.noveltyMenu.transportationMenu',
          name: translate('navigation.transportation.text'),
          children: translatingMenuItemArray(['allTransportation', 'airplanes', 'bicycles', 'boats', 'cars', 'construction', 'helicopters', 'hotAirBalloon', 'motorcycles',
            'retroCampers', 'rocketShip', 'spaceShip', 'ships', 'tractors', 'trucks'], 'topicMenu.noveltyMenu.transportationMenu')
        },
      ]
    },
    {
      id: 'topicMenu.occasionMenu',
      name: translate('navigation.occasion.text'),
      children: [
        // eslint-disable-next-line max-lines
        {
          id: 'topicMenu.occasionMenu.babyAndKidsMenu',
          name: translate('navigation.babyAndKids.text'),
          children: translatingMenuItemArray(['allChildren', 'alphabet', 'babyBoy', 'babyGirl', 'ballerina', 'butterfly', 'cars', 'circus',
            'dinosaur', 'doll', 'dragon', 'fairy', 'nursery', 'pirate', 'princess', 'robot', 'tractors', 'trucks', 'unicorn'], 'topicMenu.occasionMenu.babyAndKidsMenu')
        },
        {
          id: 'topicMenu.occasionMenu.birthdayMenu',
          name: translate('navigation.birthday.text'),
          children: translatingMenuItemArray(['allBirthday', 'balloons', 'cake', 'confetti', 'cupcake', 'gifts', 'presents', 'picnic', 'wedding', 'graduation'], 'topicMenu.occasionMenu.birthdayMenu')
        },
        {
          id: 'topicMenu.occasionMenu.picnic', name: translate('navigation.picnic.text')
        },
        {
          id: 'topicMenu.occasionMenu.wedding', name: translate('navigation.wedding.text')
        },
        {
          id: 'topicMenu.occasionMenu.graduation', name: translate('navigation.graduation.text')
        }
      ]

    },
    {
      id: 'topicMenu.seasonalMenu',
      name: translate('navigation.seasonal.text'),
      children: [

        {
          id: 'topicMenu.seasonalMenu.allSeasonal',
          name: translate('navigation.allSeasonal.text'),
          href: translate('navigation.allSeasonal.href')
        },
        {
          id: 'topicMenu.seasonalMenu.winterMenu',
          name: translate('navigation.winter.text'),
          children: translatingMenuItemArray(['allWinter', 'antler', 'chanukah', 'christmas', 'holiday', 'holly', 'iceSkates', 'penguins', 'presents',
            'polarBears', 'santa', 'skiing', 'snow', 'stockings'], 'topicMenu.seasonalMenu.winterMenu'),
        },
        {
          id: 'topicMenu.seasonalMenu.springMenu',
          name: translate('navigation.spring.text'),
          children: translatingMenuItemArray(['allSpring', 'birds', 'bunny', 'crocus', 'daffodil', 'floral', 'flowers', 'rain', 'tulips', 'umbrella'], 'topicMenu.seasonalMenu.springMenu'),
        },
        {
          id: 'topicMenu.seasonalMenu.summerMenu',
          name: translate('navigation.summer.text'),
          children: translatingMenuItemArray(
            ['allSummer', 'beach', 'camping', 'floral', 'july4th', 'summerIceCream', 'lemonade', 'mermaid', 'popsicle', 'tiki', 'tropical'],
            'topicMenu.seasonalMenu.summerMenu'
          ),
        },
        {
          id: 'topicMenu.seasonalMenu.fallMenu',
          name: translate('navigation.fall.text'),
          children: translatingMenuItemArray(['allFall', 'apple', 'autumn', 'backpack', 'camping', 'cider', 'halloween',
            'leaves', 'pumpkins', 'school', 'skull', 'teacher', 'trees', 'woodlandFall'], 'topicMenu.seasonalMenu.fallMenu'),
        },
        {
          id: 'topicMenu.seasonalMenu.seasonalHolidaysMenu',
          name: translate('navigation.seasonalHolidays.text'),
          children: translatingMenuItemArray(['allSeasonal', 'hearts', 'menorah', 'pumpkins', 'santaClaus', 'skull',
            'spider', 'starsAndStripes', 'supernatural', 'witches', 'zombies'], 'topicMenu.seasonalMenu.seasonalHolidaysMenu'),
        }
      ]
    },
    {
      id: 'topicMenu.holidaysMenu',
      name: translate('navigation.holidays.text'),
      children: [
        {
          id: 'topicMenu.holidaysMenu.allHolidays',
          name: translate('navigation.allHolidays.text'),
          href: translate('navigation.allHolidays.href')
        },
        {
          id: 'topicMenu.holidaysMenu.christmasMenu',
          name: translate('navigation.christmas.text'),
          children: translatingMenuItemArray(['allChristmas', 'christmasTree', 'holly', 'pinecones', 'reindeer',
            'retroChristmas', 'santa', 'sled', 'sleigh', 'snow', 'snowmen', 'stocking'], 'topicMenu.holidaysMenu.christmasMenu'),
        },
        {
          id: 'topicMenu.holidaysMenu.july4thMenu',
          name: translate('navigation.july4th.text'),
          children: translatingMenuItemArray(['allJuly4th', 'americanFlag', 'cookout', 'fireworks', 'hamburgers',
            'hotdogs', 'independenceDay', 'patriotic', 'picnic', 'redWhiteAndBlue', 'stars', 'stripes'], 'topicMenu.holidaysMenu.july4thMenu'),
        },
        {
          id: 'topicMenu.holidaysMenu.valentinesDayMenu',
          name: translate('navigation.valentinesDay.text'),
          children: translatingMenuItemArray(['allValentinesDay', 'arrows', 'candyHearts', 'cupid', 'hearts', 'love'], 'topicMenu.holidaysMenu.valentinesDayMenu'),
        },
        {
          id: 'topicMenu.holidaysMenu.halloweenMenu',
          name: translate('navigation.halloween.text'),
          children: translatingMenuItemArray(['allHalloween', 'bat', 'blackCats', 'candy', 'ghost', 'jack-o-lantern',
            'pumpkin', 'scarecrow', 'skull', 'spiderWeb', 'sugarSkulls', 'vampire', 'witch', 'zombie'], 'topicMenu.holidaysMenu.halloweenMenu'),
        }
      ]
    },
    {
      id: 'topicMenu.projectsMenu',
      name: translate('navigation.projects.text'),
      children: translatingMenuItemArray(['allProjects', 'bags', 'border', 'bunting', 'calendarTeaTowels', 'cheaterQuilts',
        'cutAndSew', 'pencilPouch', 'plushies', 'quilt', 'quiltLabels', 'stockings', 'teaTowels', 'zipperPouch'], 'topicMenu.projectsMenu')
    }
  ],
});
