import React from 'react'
import { IconProps } from '.'

export function PersonIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 -50 1024 1024'
      style={{
        transform: 'rotate(180deg)  scaleX(-1)',
      }}
      fill={color}
      role='img'
      aria-hidden
      focusable='false'
      {...props}
    >
      <path d='M511.997 960c143.487 0 259.807-107.873 259.807-240.941 0-69.696-31.909-165.528-82.919-240.999l4.776 0.058c155.759 0 286.944-116.406 305.473-271.059l23.896-199.445c4.205-35.095-20.837-66.954-55.932-71.159-2.527-0.303-5.069-0.454-7.613-0.454h-894.976c-35.346 0-64 28.654-64 64 0 2.545 0.152 5.087 0.454 7.613l23.896 199.445c18.529 154.653 149.715 271.059 305.473 271.059l4.776-0.058c-51.009 75.471-82.919 171.303-82.919 240.999 0 133.068 116.319 240.941 259.807 240.941z' />
    </svg>
  )
}
