import React from 'react'
import { IconProps } from '.'

export function HeartFilledIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 -50 1024 1024'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      style={{ transform: 'rotate(180deg)' }}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M512-64l-74.24 73.661c-263.68 260.604-437.76 432.48-437.76 643.418 0 171.876 123.904 306.921 281.6 306.921 89.088 0 174.592-45.201 230.4-116.63 55.808 71.429 141.312 116.63 230.4 116.63 157.696 0 281.6-135.045 281.6-306.921 0-210.938-174.080-382.814-437.76-643.976l-74.24-73.103z'
        fill={color}
      />
    </svg>
  )
}
