import {snakeCase} from 'lodash';
import React, {HTMLAttributes, useMemo} from 'react';

import {ON_PARAM, PRODUCT_PARAM, SUBSTRATE_PARAM, URL_QUERY_PARAM_SUBSTRATE_WALLPAPER} from '../../../constants/Parameters';
import useOrigin from '../../../entities/pageSetup/origin/useOrigin';
import useCurrentPath from '../../../entities/pageSetup/path/useCurrentPath';
import {onClickPreventDefault} from '../../../utils/events';
import {getLeafValue} from '../../../utils/search';

import {TreeBranch} from './SearchTree';


export const useSeoLabel = ({nodes, nodeProps}: {nodes: TreeBranch; nodeProps?: HTMLAttributes<HTMLAnchorElement>}): JSX.Element => {
  const {name: label, id} = nodes;
  const currentPath = useCurrentPath();
  const origin = useOrigin();

  return useMemo(() => {
    const shouldProcessLeaf = !Array.isArray(nodes.children);

    if (!shouldProcessLeaf || !nodes.apiValue) {
      return <span>{label}</span>;
    }
    const url = new URL(currentPath, origin);
    const searchValue = nodes.apiValue || '';
    const [parentCategoryName, category] = id.split('.');
    const facetParam = getLeafValue(parentCategoryName);
    const searchParam = facetParam === PRODUCT_PARAM ? ON_PARAM : facetParam;

    if (category === URL_QUERY_PARAM_SUBSTRATE_WALLPAPER) {
      url.searchParams.set(ON_PARAM, URL_QUERY_PARAM_SUBSTRATE_WALLPAPER);
      url.searchParams.set(SUBSTRATE_PARAM, snakeCase(searchValue));
    } else {
      url.searchParams.set(searchParam, searchValue);
    }
    if (['topic', 'style', 'color'].includes(searchParam)) {
      // This is exception. Someone inverted params so we need actual label here,
      // instead of searchValue (which is technically should be a key from the dictionary, but in this particular cases we have to use value(label))
      // The discrepancy is in contract now ( we are expecting dict value on a pythias side as well) Which is incorrect,
      // More preferably: we need use keys for every param and remap it on the api side if necessary (So changing label on UI side won't require the contract change).
      // https://spoonflower.atlassian.net/browse/SP-25289?focusedCommentId=85461
      url.searchParams.set(searchParam, label);
    }
    const href = url.pathname + url.search;

    return <a className='seo-label' href={href} aria-label={label} onClick={onClickPreventDefault} {...nodeProps}>{label}</a>;
  }, [nodes, origin, currentPath, label, id]);
};
