import { MenuLink } from '../Menu'

export const white: MenuLink[] = [
  {
    key: 'White',
    url: '/en/shop/white',
  },
  {
    key: 'Beige',
    url: '/en/shop/beige',
  },
  {
    key: 'Cornsilk',
    url: '/en/shop/cornsilk',
  },
  {
    key: 'Ecru',
    url: '/en/shop/ecru',
  },
  {
    key: 'Eggshell',
    url: '/en/shop/eggshell',
  },
  {
    key: 'Flax',
    url: '/en/shop/flax',
  },
  {
    key: 'Ivory',
    url: '/en/shop/ivory',
  },
  {
    key: 'Linen',
    url: '/en/shop/linen',
  },
  {
    key: 'Vanilla',
    url: '/en/shop/vanilla',
  },
  {
    key: 'Smoke',
    url: '/en/shop/smoke',
  },
]

export const grey: MenuLink[] = [
  {
    key: 'Gray',
    url: '/en/shop/gray',
  },
  {
    key: 'Grey',
    url: '/en/shop/grey',
  },
  {
    key: 'Ash',
    url: '/en/shop/ash',
  },
  {
    key: 'Battleship',
    url: '/en/shop/battleship',
  },
  {
    key: 'BlueGray',
    url: '/en/shop/blueGray',
  },
  {
    key: 'Charcoal',
    url: '/en/shop/charcoal',
  },
  {
    key: 'Gunmetal',
    url: '/en/shop/gunmetal',
  },
  {
    key: 'Platinum',
    url: '/en/shop/platinum',
  },
  {
    key: 'Silver',
    url: '/en/shop/silver',
  },
  {
    key: 'Slate',
    url: '/en/shop/slate',
  },
  {
    key: 'Taupe',
    url: '/en/shop/taupe',
  },
]

export const pink: MenuLink[] = [
  {
    key: 'Pink',
    url: '/en/shop/pink',
  },
  {
    key: 'Carnation',
    url: '/en/shop/carnation',
  },
  {
    key: 'Cerise',
    url: '/en/shop/cerise',
  },
  {
    key: 'Coral',
    url: '/en/shop/coral',
  },
  {
    key: 'Cyclamen',
    url: '/en/shop/cyclamen',
  },
  {
    key: 'Fandango',
    url: '/en/shop/fandango',
  },
  {
    key: 'Fuschsia',
    url: '/en/shop/fuschsia',
  },
  {
    key: 'Hot',
    url: '/en/shop/hot',
  },
  {
    key: 'Magenta',
    url: '/en/shop/magenta',
  },
  {
    key: 'Mauve',
    url: '/en/shop/mauve',
  },
  {
    key: 'Puce',
    url: '/en/shop/puce',
  },
  {
    key: 'Raspberry',
    url: '/en/shop/raspberry',
  },
  {
    key: 'Rose',
    url: '/en/shop/rose',
  },
  {
    key: 'Quartz',
    url: '/en/shop/quartz',
  },
  {
    key: 'Ruby',
    url: '/en/shop/ruby',
  },
  {
    key: 'Salmon',
    url: '/en/shop/salmon',
  },
  {
    key: 'Shocking',
    url: '/en/shop/shocking',
  },
]

export const red: MenuLink[] = [
  {
    key: 'Red',
    url: '/en/shop/red',
  },
  {
    key: 'Auburn',
    url: '/en/shop/auburn',
  },
  {
    key: 'Burgundy',
    url: '/en/shop/burgundy',
  },
  {
    key: 'Cardinal',
    url: '/en/shop/cardinal',
  },
  {
    key: 'Claret',
    url: '/en/shop/claret',
  },
  {
    key: 'Crimson',
    url: '/en/shop/crimson',
  },
  {
    key: 'Mahogany',
    url: '/en/shop/mahogany',
  },
  {
    key: 'Maroon',
    url: '/en/shop/maroon',
  },
  {
    key: 'Marsala',
    url: '/en/shop/marsala',
  },
  {
    key: 'Oxblood',
    url: '/en/shop/oxblood',
  },
  {
    key: 'Raspberry',
    url: '/en/shop/raspberry',
  },
  {
    key: 'Redwood',
    url: '/en/shop/redwood',
  },
  {
    key: 'Rosewood',
    url: '/en/shop/rosewood',
  },
  {
    key: 'Ruby',
    url: '/en/shop/ruby',
  },
  {
    key: 'Rust',
    url: '/en/shop/rust',
  },
  {
    key: 'Scarlet',
    url: '/en/shop/scarlet',
  },
  {
    key: 'Terra',
    url: '/en/shop/terra',
  },
  {
    key: 'Vermilion',
    url: '/en/shop/vermilion',
  },
]

export const brown: MenuLink[] = [
  {
    key: 'Brown',
    url: '/en/shop/brown',
  },
  {
    key: 'Beige',
    url: '/en/shop/beige',
  },
  {
    key: 'Bronze',
    url: '/en/shop/bronze',
  },
  {
    key: 'Buff',
    url: '/en/shop/buff',
  },
  {
    key: 'Umber',
    url: '/en/shop/umber',
  },
  {
    key: 'Copper',
    url: '/en/shop/copper',
  },
  {
    key: 'Desert',
    url: '/en/shop/desert',
  },
  {
    key: 'Ecru',
    url: '/en/shop/ecru',
  },
  {
    key: 'Khaki',
    url: '/en/shop/khaki',
  },
  {
    key: 'Mahogany',
    url: '/en/shop/mahogany',
  },
  {
    key: 'Ochre',
    url: '/en/shop/ochre',
  },
  {
    key: 'Russet',
    url: '/en/shop/russet',
  },
  {
    key: 'Rust',
    url: '/en/shop/rust',
  },
  {
    key: 'Sand',
    url: '/en/shop/sand',
  },
  {
    key: 'Sepia',
    url: '/en/shop/sepia',
  },
  {
    key: 'Sienna',
    url: '/en/shop/sienna',
  },
  {
    key: 'Tan',
    url: '/en/shop/tan',
  },
  {
    key: 'Taupe',
    url: '/en/shop/taupe',
  },
  {
    key: 'Wheat',
    url: '/en/shop/wheat',
  },
]

export const orange: MenuLink[] = [
  {
    key: 'Orange',
    url: '/en/shop/orange',
  },
  {
    key: 'Amber',
    url: '/en/shop/amber',
  },
  {
    key: 'Apricot',
    url: '/en/shop/apricot',
  },
  {
    key: 'Coral',
    url: '/en/shop/coral',
  },
  {
    key: 'Marigold',
    url: '/en/shop/marigold',
  },
  {
    key: 'Red',
    url: '/en/shop/red',
  },
  {
    key: 'Peach',
    url: '/en/shop/peach',
  },
  {
    key: 'Salmon',
    url: '/en/shop/salmon',
  },
  {
    key: 'Tangerine',
    url: '/en/shop/tangerine',
  },
]

export const yellow: MenuLink[] = [
  {
    key: 'Yellow',
    url: '/en/shop/yellow',
  },
  {
    key: 'Citrine',
    url: '/en/shop/citrine',
  },
  {
    key: 'Gold',
    url: '/en/shop/gold',
  },
  {
    key: 'Goldenrod',
    url: '/en/shop/goldenrod',
  },
  {
    key: 'Honey',
    url: '/en/shop/honey',
  },
  {
    key: 'Jonquil',
    url: '/en/shop/jonquil',
  },
  {
    key: 'Mustard',
    url: '/en/shop/mustard',
  },
  {
    key: 'Saffron',
    url: '/en/shop/saffron',
  },
]

export const green: MenuLink[] = [
  {
    key: 'Green',
    url: '/en/shop/green',
  },
  {
    key: 'Artichoke',
    url: '/en/shop/artichoke',
  },
  {
    key: 'Celadon',
    url: '/en/shop/celadon',
  },
  {
    key: 'Chartreuse',
    url: '/en/shop/chartreuse',
  },
  {
    key: 'Emerald',
    url: '/en/shop/emerald',
  },
  {
    key: 'Jade',
    url: '/en/shop/jade',
  },
  {
    key: 'Kelly',
    url: '/en/shop/kelly',
  },
  {
    key: 'Mint',
    url: '/en/shop/mint',
  },
]

export const cyan: MenuLink[] = [
  {
    key: 'Cyan',
    url: '/en/shop/cyan',
  },
  {
    key: 'Aqua',
    url: '/en/shop/aqua',
  },
  {
    key: 'Aquamarine',
    url: '/en/shop/aquamarine',
  },
  {
    key: 'Cerulean',
    url: '/en/shop/cerulean',
  },
  {
    key: 'Teal',
    url: '/en/shop/teal',
  },
  {
    key: 'Turquoise',
    url: '/en/shop/turquoise',
  },
  {
    key: 'Verdigris',
    url: '/en/shop/verdigris',
  },
]

export const blue: MenuLink[] = [
  {
    key: 'Blue',
    url: '/en/shop/blue',
  },
  {
    key: 'Azure',
    url: '/en/shop/azure',
  },
  {
    key: 'Baby',
    url: '/en/shop/baby',
  },
  {
    key: 'Cobalt',
    url: '/en/shop/cobalt',
  },
  {
    key: 'Cornflower',
    url: '/en/shop/cornflower',
  },
  {
    key: 'Denim',
    url: '/en/shop/denim',
  },
  {
    key: 'Indigo',
    url: '/en/shop/indigo',
  },
  {
    key: 'Midnight',
    url: '/en/shop/midnight',
  },
  {
    key: 'Navy',
    url: '/en/shop/navy',
  },
  {
    key: 'Periwinkle',
    url: '/en/shop/periwinkle',
  },
  {
    key: 'Sapphire',
    url: '/en/shop/sapphire',
  },
  {
    key: 'Serenity',
    url: '/en/shop/serenity',
  },
  {
    key: 'Ultramarine',
    url: '/en/shop/ultramarine',
  },
]

export const violet: MenuLink[] = [
  {
    key: 'Violet',
    url: '/en/shop/violet',
  },
  {
    key: 'Amethyst',
    url: '/en/shop/amethyst',
  },
  {
    key: 'Aubergine',
    url: '/en/shop/aubergine',
  },
  {
    key: 'Cerise',
    url: '/en/shop/cerise',
  },
  {
    key: 'Eggplant',
    url: '/en/shop/eggplant',
  },
  {
    key: 'Fuchsia',
    url: '/en/shop/fuchsia',
  },
  {
    key: 'Indigo',
    url: '/en/shop/indigo',
  },
  {
    key: 'Lavender',
    url: '/en/shop/lavender',
  },
  {
    key: 'Lilac',
    url: '/en/shop/lilac',
  },
  {
    key: 'Magenta',
    url: '/en/shop/magenta',
  },
  {
    key: 'Orchid',
    url: '/en/shop/orchid',
  },
  {
    key: 'Periwinkle',
    url: '/en/shop/periwinkle',
  },
  {
    key: 'Plum',
    url: '/en/shop/plum',
  },
  {
    key: 'Purple',
    url: '/en/shop/purple',
  },
  {
    key: 'Radiant',
    url: '/en/shop/radiant',
  },
]

export const black: MenuLink[] = [
  {
    key: 'Black',
    url: '/en/shop/black',
  },
  {
    key: 'Charcoal',
    url: '/en/shop/charcoal',
  },
  {
    key: 'Ebony',
    url: '/en/shop/ebony',
  },
  {
    key: 'Midnight',
    url: '/en/shop/midnight',
  },
  {
    key: 'Noir',
    url: '/en/shop/noir',
  },
  {
    key: 'Onyx',
    url: '/en/shop/onyx',
  },
]

export const colors: MenuLink[] = [
  {
    key: 'White',
    childrenItems: white,
  },
  {
    key: 'Grey',
    childrenItems: grey,
  },
  {
    key: 'Pink',
    childrenItems: pink,
  },
  {
    key: 'Red',
    childrenItems: red,
  },
  {
    key: 'Brown',
    childrenItems: brown,
  },
  {
    key: 'Orange',
    childrenItems: orange,
  },
  {
    key: 'Yellow',
    childrenItems: yellow,
  },
  {
    key: 'Green',
    childrenItems: green,
  },
  {
    key: 'Cyan',
    childrenItems: cyan,
  },
  {
    key: 'Blue',
    childrenItems: blue,
  },
  {
    key: 'Violet',
    childrenItems: violet,
  },
  {
    key: 'Black',
    childrenItems: black,
  },
]
